import { EventNameValue, EventMapper, EventNameKey } from '../types'
import { track_events } from '../events'
import { isEmpty } from 'lodash'

import global from '../event-handlers/global'
import account from '../event-handlers/account'
import survey from '../event-handlers/survey'

/**
 * `eventToAction` returns `undefined` as value for every key on `track_events` object
 * @param events
 */
export function eventToAction<E = typeof track_events & { [k: string]: string }>(events: E) {
  const event_actions = { ...events }
  Object.keys(event_actions).forEach(e => (event_actions[e] = undefined))
  return event_actions as { [e in EventNameKey]: undefined }
}

/**
 * `getEventMapper` returns events handlers object
 */
export function getEventMapper(): EventMapper {
  return Object.assign({}, global.events, account.events, survey.events)
}

/**
 * `getEventName` uses `eventType` as key of the `events` object to return the EventNameValue as string
 * @param eventType
 * @param events
 */
export function getEventName<A extends string, E = EventMapper>(eventType: A, events: E): EventNameValue | string {
  return events[eventType?.replace(/\w+\//gi, '')]
}

/**
 * `stringOrUndefined` will return `undefined` if argument is not of type `string` or if value is empty. Otherwise, return the argument
 * @param string
 */
export function stringOrUndefined<S = string>(string: S): undefined | S {
  if (typeof string !== 'string') return undefined
  return isEmpty(string) ? undefined : string
}
/**
 * `trueOrUndefined` will return `undefined` if argument is not of type `boolean` or if value is false. Otherwise, return the argument
 * @param boolean
 */
export function trueOrUndefined<B = boolean>(boolean: B): undefined | B {
  if (typeof boolean !== 'boolean') return undefined
  return !boolean ? undefined : boolean
}
/**
 * `numberOrUndefined` will return `undefined` if argument is not of type `number`. Otherwise, return the argument
 * @param number
 */
export function numberOrUndefined<N = number>(number: N): undefined | N {
  if (typeof number !== 'number') return undefined
  return number
}
