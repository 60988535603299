import { createActions } from 'redux-actions'
import { DefaultRoutine, DefaultRoutineActions, BasicActionFunction } from '@pvolve/sdk/src/app/utils'

export const SEARCH_FIELDS = {
  workout: {
    id: 'sys.id',
    benefit: 'fields.benefit.sys.id',
    duration: 'duration',
    equipment: 'fields.equipment.sys.id',
    bodyFocus: 'fields.bodyFocus.sys.id',
    instructor: 'fields.instructor.sys.id',
    level: 'fields.level',
    workoutCategory: 'fields.workoutCategory.sys.id',
    workoutType: 'fields.workoutType.sys.id',
  },
}

export interface SearchQuery {
  name: string
  contentType: keyof typeof SEARCH_FIELDS
  query: {
    [key in keyof typeof SEARCH_FIELDS]: string[]
  }
}

export interface SearchResult<T> {
  item: T
}

interface ContentActions {
  content: {
    load: BasicActionFunction
    clearSearch: BasicActionFunction
    loaded: BasicActionFunction
    find: DefaultRoutineActions // TODO add types here
    findAll: DefaultRoutineActions
    search: DefaultRoutineActions<SearchQuery | object>
    index: DefaultRoutineActions
  }
}

const Actions = <ContentActions>(<unknown>createActions({
  CONTENT: {
    CLEAR_SEARCH: undefined,
    FIND: DefaultRoutine,
    LOAD: undefined,
    FIND_ALL: DefaultRoutine,
    LOADED: undefined,
    SEARCH: DefaultRoutine,
    INDEX: DefaultRoutine,
  },
}))

export enum ContentTypes {
  asset = 'asset',
  series = 'series',
  workout = 'workout',
  benefit = 'benefit',
  equipment = 'equipment',
  bodyFocus = 'bodyFocus',
  instructor = 'instructor',
  workoutCategory = 'workoutCategory',
  workoutType = 'workoutType',
  seriesCategory = 'seriesCategory',
  seriesChapter = 'seriesChapter',
  page = 'page',
}

export const WORKOUT_LEVELS = {
  beginner: 'beginner',
  advanced: 'advanced',
}

export const WORKOUT_DURATIONS = {
  '1 - 15': [0, 15],
  '16 - 30': [16, 30],
  '31 - 45': [31, 45],
  '46 - 60': [46],
  '61': [61],
}

export default Actions
