import { takeEvery } from 'redux-saga/effects'
import { loginRequired, routineSaga } from '@pvolve/sdk/src/app/utils/sagas'
import Actions from './actions'
import Pvolve from '@pvolve/sdk'

const {
  challenge: { list },
} = Actions

class ChallengeSaga {
  *init() {
    yield takeEvery(list.trigger, loginRequired(saga.list))
  }

  list = routineSaga({
    routine: list,
    *request() {
      const response = yield Pvolve.api.account.listSignedUpChallenges()
      return response
    },
  })
}

export const saga = new ChallengeSaga()
