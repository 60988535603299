import { takeEvery } from 'redux-saga/effects'
import Actions from './actions'
import { loginBetaUser, routineSaga } from '../../utils/sagas'
import Pvolve from '../../../index'

const {
  flags: { phaseAndFunction },
} = Actions

class PhaseSaga {
  *init() {
    yield takeEvery(phaseAndFunction.trigger, loginBetaUser(saga.phaseAndFunction))
  }

  phaseAndFunction = routineSaga({
    routine: phaseAndFunction,
    *request(payload: any) {
      const response = yield Pvolve.api.featureFlag.featureFlagByName(payload)
      return response
    },
  })
}

export const saga = new PhaseSaga()
