import { createActions } from 'redux-actions'
import { DefaultRoutine } from '@pvolve/sdk/src/app/utils'
import { DefaultRoutineActions } from '../../utils'

interface SettingsActions {
  settings: {
    loadCommunications: DefaultRoutineActions
    updateCommunications: DefaultRoutineActions
    displayWifiAlert: DefaultRoutineActions<{ visible: boolean }>
  }
}

const Actions = <SettingsActions>(<unknown>createActions({
  SETTINGS: {
    LOAD_COMMUNICATIONS: DefaultRoutine,
    UPDATE_COMMUNICATIONS: DefaultRoutine,
    DISPLAY_WIFI_ALERT: DefaultRoutine,
  },
}))

export default Actions
