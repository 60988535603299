import { select, take, race, delay, put } from 'redux-saga/effects'

import { PutRecordsRequestEntryList } from 'aws-sdk/clients/kinesis'
//import AWS from 'aws-sdk/dist/aws-sdk-react-native';

import Actions from '../../actions'
import Pvolve from '@pvolve/sdk'
import Selectors from '@pvolve/sdk/src/app/selectors'
import { EventPayload, VideoEventType } from '@pvolve/sdk/src/app/types'

const {
  analytics: { track, eventsPut },
} = Actions

const BUFFER_TIMEOUT = 20000
const IMMEDIATE_SEND_EVENTS = [VideoEventType.VIDEO_CLOSE, VideoEventType.VIDEO_END]

// TODO move this logic to SDK
interface Event extends EventPayload {
  session_id?: string
}

interface VideoEvent extends Event {
  first_timecode?: number
  last_timecode?: number
  time?: number
  event_type: string
}

type VideoEventList = VideoEvent[]

class AnalyticsSaga {
  AWS: any

  constructor() {}

  *init() {
    const { AWS } = this
    const bufferSize = 5
    let buffer: unknown[] = []

    let previousTimecode: number | null = null
    let sessionTimestamp = new Date().getTime()
    let containsImmediateSendEvents = false

    const device = yield Pvolve.api.config.find('device')

    while (true) {
      const { trackingEvent, timeout, unauthorized } = yield race({
        unauthorized: take(Actions.auth.unauthenticated),
        trackingEvent: take(track),
        timeout: delay(BUFFER_TIMEOUT),
      })

      const loggedIn = yield select(Selectors.auth.loggedIn)

      if (trackingEvent) {
        const { payload: event }: { payload: EventPayload } = trackingEvent

        const user = yield select(Selectors.auth.user)

        const videoEvent: VideoEvent = {
          ...event,
        }

        if (IMMEDIATE_SEND_EVENTS.includes(videoEvent.event_type)) {
          containsImmediateSendEvents = true
        }

        if (event.event_type === VideoEventType.VIDEO_LOADED) {
          sessionTimestamp = new Date().getTime()
        } else if (event.event_type === VideoEventType.VIDEO_PROGRESS) {
          videoEvent.first_timecode = previousTimecode || videoEvent.time
          videoEvent.last_timecode = videoEvent.time

          if (videoEvent.last_timecode < videoEvent.first_timecode) {
            videoEvent.first_timecode = videoEvent.last_timecode
          }
          previousTimecode = videoEvent.last_timecode
        } else if ([VideoEventType.VIDEO_PLAY, VideoEventType.VIDEO_SEEK].includes(videoEvent.event_type)) {
          previousTimecode = null
        }

        const sessionId = `${user?.sub || event.visitor_id}+${event.model_id}+${sessionTimestamp}`
        videoEvent.session_id = event.session_id || sessionId

        const record = {
          Data: JSON.stringify(videoEvent),
          PartitionKey: user ? `user-${user.sub}` : `visitor-${event.visitor_id}`, // maybe it should be the user id????
        }

        if (device === 'web') {
          buffer.push(videoEvent)
        } else {
          buffer.push(record)
        }
      } else if (unauthorized && buffer.length > 0) {
        console.warn(`logged out before ${buffer.length} buffer events were sent`)
        buffer = []
      }

      if (!loggedIn) {
        buffer = []
      }

      if (buffer.length >= bufferSize || (buffer.length > 0 && (timeout || containsImmediateSendEvents))) {
        containsImmediateSendEvents = false
        try {
          if (device === 'web') {
            // Send the event through API Gateway
            const putResponse = yield Pvolve.api.workout.video_events(buffer)
            yield put(eventsPut(putResponse))
          } else {
            // Send the event directly to Kinesis

            const { region, userPoolId, identityPoolId } = yield Pvolve.api.cognito.config()
            const { id: idToken } = yield Pvolve.api.tokens.freshTokens()

            // Add the User's Id Token to the Cognito credentials login map.
            AWS.config.credentials = new AWS.CognitoIdentityCredentials(
              {
                IdentityPoolId: identityPoolId,
                Logins: {
                  [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
                },
              },
              { region },
            )

            const Kinesis = new AWS.Kinesis({
              apiVersion: '2013-12-02',
              region,
              credentials: AWS.config.credentials,
            })

            const StreamName = yield Pvolve.api.config.find('analytics.streams.video')
            const putResponse = yield Kinesis.putRecords({
              Records: buffer,
              // TODO get this from config
              StreamName,
            }).promise()
            yield put(eventsPut(putResponse))
          }

          buffer = []
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}

export const saga = new AnalyticsSaga()
