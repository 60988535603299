import { createSelector } from 'reselect'
import { IMusic, IState } from '../../../redux/selectors'

const music = (state: IState) => state.music

const currentStation = createSelector(music, (music: IMusic) => {
  return music.currentStation
})

const defaultStation = createSelector(music, (music: IMusic) => {
  return music.defaultStation
})

const stations = createSelector(music, (music: IMusic) => {
  return music.stations
})

const trainerVolume = createSelector(music, (music: IMusic) => {
  return music.trainerVolume
})

const volume = createSelector(music, (music: IMusic) => {
  return music.volume
})

export default {
  currentStation,
  defaultStation,
  music,
  stations,
  trainerVolume,
  volume,
}
