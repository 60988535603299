import { handleActionsImmer } from '../../utils'
import Actions from '../../actions'

export const initialState = {
  workoutCategoriesContent: {
    targetedCategories: null,
    defaultCategories: null,
  },
}

const {
  workoutCategories: { loadWorkoutCategoriesContent },
} = Actions

const Reducer = handleActionsImmer(
  {
    [loadWorkoutCategoriesContent.success]: (draft, data: any) => {
      draft.workoutCategoriesContent = data
    },
  },
  initialState,
)

export default Reducer
