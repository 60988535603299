import { set } from 'lodash'
import { handleActionsImmer } from '@pvolve/sdk/src/app/utils'
import Actions from '../../actions'

export const initialState = {
  enrolled: {},
  loadingFinished: false,
  seriesFeatured: [],
  highlightedSeries: {},
  allSeriesTargeted: [],
}

const {
  auth: { logout },
  series: { enrolled, seriesFeatured, loadHighlightedSeries, loadAllSeriesTargeted },
} = Actions

const Reducer = handleActionsImmer(
  {
    [logout.success]: () => initialState,
    [enrolled.success]: (draft, data) => {
      if (data) {
        data.map(item => set(draft, `enrolled.${item.series_id}`, item))
      }
      draft.loadingFinished = true
    },
    [seriesFeatured.success]: (draft, data) => {
      if (data) {
        draft.seriesFeatured = data
      }
    },
    [loadHighlightedSeries.success]: (draft, data) => {
      draft.highlightedSeries = data
    },
    [loadAllSeriesTargeted.success]: (draft, data) => {
      draft.allSeriesTargeted = data
    },
  },
  initialState,
)

export default Reducer
