import defaultAxios from 'axios'
import qs from 'querystring'

export default class AxiosAdapter {
  constructor(axios) {
    this.axios = axios
  }

  async request({ data, headers, ...rest }) {
    const { axios } = this
    const options = { headers, ...rest }

    if (data) {
      if (typeof data === 'object') {
        if (headers['content-type'] === 'application/x-www-form-urlencoded') {
          options.data = qs.stringify(data)
        } else {
          options.data = data
        }
      } else {
        options.data = data
      }
    }

    try {
      const response = await axios(options)
      return response
    } catch (error) {
      if (!error.response) {
        this.axios = defaultAxios.create()
      }

      throw error
    }
  }
}
