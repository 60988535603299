import { get } from 'lodash'

import BaseService from './BaseService'

const DEFAULT_MAX_AGE_MILLIS = 60 * 5 * 1000
const EPOCH = new Date(0).getTime()

function now() {
  return new Date().getTime()
}

export interface EnvironmentOptions {
  [key: string]: {
    uri: string
  }
}

export default class ConfigService extends BaseService {
  maxAgeMillis: number
  lastAttemptedUpdateAt: number
  private _configStore: any
  initialEnvironmentOptions?: EnvironmentOptions

  constructor(environmentOptions?: EnvironmentOptions | undefined) {
    super({})
    this.maxAgeMillis = DEFAULT_MAX_AGE_MILLIS
    this.lastAttemptedUpdateAt = 0
    this.initialEnvironmentOptions = environmentOptions
  }

  getEnvironmentOptions() {
    return <EnvironmentOptions>this.findSync('environment')
  }

  async setCurrentEnvironment(name: string) {
    await this.refreshConfig(name)
  }

  get currentEnvironment() {
    const { initialEnvironmentOptions } = this
    return this.findSync('environment') || Object.keys(initialEnvironmentOptions || {})[0]
  }

  async find(path: string) {
    await this.freshConfig()
    return this.findSync(path)
  }

  findSync(path: string) {
    return get(this.configRoot, path)
  }

  async refreshConfig(name?: string) {
    //throw "implement refreshConfig";
    return this
  }

  updateConfig(config: any, version: string) {
    const { version: lastVersion } = this.configStore.metadata
    this.lastAttemptedUpdateAt = now()

    if (!version || (version && lastVersion !== version)) {
      this.configStore.updateConfig(config, { version, updatedAt: now() })
    }
    return this
  }

  /**
   *
   * Make sure config is valid and if it isn't then refresh it
   */
  async freshConfig() {
    if (this.expired()) {
      await this.refreshConfig()
    }

    return this
  }

  expiresAt() {
    const { updatedAt: dataUpdatedAt } = this.configStore.metadata
    const updatedAt = Math.max(dataUpdatedAt || 0, this.lastAttemptedUpdateAt)
    this.maxAgeMillis = get(this.configRoot, 'maxAgeMillis', this.maxAgeMillis)
    return updatedAt ? updatedAt + this.maxAgeMillis : EPOCH
  }

  expired() {
    return !this.configRoot || now() >= this.expiresAt()
  }

  async clear() {
    await this.configStore.clear()
  }

  get configRoot() {
    return this.configStore.config
  }

  get configStore() {
    return this._configStore
  }

  set configStore(configStore) {
    this._configStore = configStore
  }
}
