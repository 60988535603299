import AnalyticsActions from './modules/analytics/actions'
import AuthActions from './modules/auth/actions'
import ContentActions from './modules/content/actions'
import ConfigActions from './modules/config/actions'
import FavoritesActions from './modules/favorites/actions'
import MusicActions from './modules/music/actions'
import QuestionnaireActions from './modules/questionnaire/actions'
import SeriesActions from './modules/series/actions'
import SettingsActions from './modules/settings/actions'
import WorkoutsActions from './modules/workouts/actions'
import WorkoutCategoriesActions from './modules/workoutCategories/actions'
import Subscriptions from './modules/subscriptions/actions'
import VideoActions from './modules/video/actions'
import PhaseActions from './modules/phaseFunction/actions'
import FeatureFlagsActions from './modules/featureFlag/actions'
import SeriesCategory from './modules/seriesCategory/actions'
import ChallengeActions from './modules/challenge/actions'

export default {
  analytics: AnalyticsActions.analytics,
  auth: AuthActions.auth,
  content: ContentActions.content,
  config: ConfigActions.config,
  favorites: FavoritesActions.favorites,
  music: MusicActions.music,
  questionnaire: QuestionnaireActions.questionnaire,
  series: SeriesActions.series,
  settings: SettingsActions.settings,
  workouts: WorkoutsActions.workouts,
  workoutCategories: WorkoutCategoriesActions.workoutCategories,
  subscriptions: Subscriptions.subscriptions,
  phases: PhaseActions.phases,
  video: VideoActions.video,
  flags: FeatureFlagsActions.flags,
  seriesCategory: SeriesCategory.seriesCategory,
  challenge: ChallengeActions.challenge,
}
