import { handleActionsImmer, RoutineState, RoutineInitialState } from '@pvolve/sdk/src/app/utils'
import Actions from '@pvolve/sdk/src/app/actions'
import { set } from 'lodash'
import { IContentState } from '@pvolve/sdk/src/redux/selectors'

export interface ContentState extends IContentState {
  data: { [key: string]: any }
  find: RoutineState
  searches: {
    default: {}
  }
}

export const initialState: ContentState = {
  data: {},
  find: RoutineInitialState,
  searches: {
    default: {},
  },
}

const {
  content: { clearSearch, find, load, loaded, search, findAll },
} = Actions

export default handleActionsImmer<ContentState>(
  {
    [findAll.success]: (draft: ContentState, { results }: any) => {
      for (const [contentType, result] of Object.entries(results)) {
        if (result?.status === 'fulfilled') {
          set(draft, `data.${contentType}`, result.value?.content)
        }
      }
    },
    [find.success]: (draft: ContentState, { response: { content }, contentType }: any) => {
      set(draft, `data.${contentType}`, content)
    },
    [search.trigger]: (draft: ContentState, { contentType, query, name = 'default' }: any) => {
      set(draft, `searches.${name}.query`, query)
    },
    [search.success]: (
      draft: ContentState,
      { name, results, resultsWithAdditionalEquipment, resultsWithEquipmentOnly, wasFilteredByEquipment }: any,
    ) => {
      set(draft, `searches.${name}.results`, results)
      set(draft, `searches.${name}.resultsWithEquipmentOnly`, resultsWithEquipmentOnly)
      set(draft, `searches.${name}.resultsWithAdditionalEquipment`, resultsWithAdditionalEquipment)
      set(draft, `searches.${name}.wasFilteredByEquipment`, wasFilteredByEquipment)
    },
    [load]: draft => {
      draft.loading = true
    },
    [loaded]: draft => {
      draft.loading = false
    },
    [clearSearch]: (draft, data = {}) => {
      const { name = 'default' } = data
      set(draft, `searches.${name}`, {})
    },
  },
  initialState,
  {
    find,
    findAll,
  },
)
