import BaseService from './BaseService'
import { compact } from 'lodash'

export enum PaymentSetupStatus {
  requires_payment_method = 'requires_payment_method',
  requires_confirmation = 'requires_confirmation',
  requires_action = 'requires_action',
  processing = 'processing',
  canceled = 'canceled',
  succeeded = 'succeeded',
}

export interface PriceSummary {
  currency: string
  subtotal: number
  shipping: number
  taxes: number
  discount: number
  total: number
}

export interface Discount {
  amount: number
  title: string
  applies_to_cart: boolean
  bundle_product_skus: null | any
  bundle_susbscription_skus: null | any
  code: string
  created_at: any
  entitled_skus: string[]
  max_usage: number
  minimum_amount: number
  percentage: number
  type: string
  updated_at: any
  usage_count: number
  valid_until: null | any
  is_permanent: boolean
  max_intervals?: number
}

export interface ItemDiscount {
  discount: number
  is_shipping_discount: boolean
  sku: string
}

export interface DiscountSummary {
  discount: Discount
  description: string
  total_discount: number
  item_discounts: ItemDiscount[]
}

export interface GetDiscountResponse {
  response_code: number
  message: string
  data: Discount[]
}

export type PurchaseResponse = {
  response_code: number
  message: string
  data: {
    draft_order_id: number
    price_summary: {
      currency: string
      subtotal: number
      shipping: number | null
      taxes: number | null
      discount: number | null
      total: number
    }
    discount_summary?: {
      description: string
      discount: Discount
      item_discounts?: ItemDiscount[]
    }
  }
}

export type GetPaymentResponse = {
  response_code: number
  message: string
  data: {
    payment_methods: Payment[] | null
  }
}

export interface Payment {
  credit_card: CreditCard
  type: string
  is_default: boolean
}

export interface CreditCard {
  payment_id: string
  brand: CreditCardBrand
  fingerprint: string
  exp_month: number
  exp_year: number
  last_four: string
  created_at: number
}

export interface LineItem {
  sku: string
  quantity: number
}

export interface ShippingAddress {
  first_name: string
  last_name: string
  address1: string
  address2?: string
  city?: string
  zip?: string
  country: string
}

export interface BillingAddress {
  zip: string
}

export interface InitiatePurchaseAttributes {
  line_items: LineItem[]
  code?: string | null
}

export interface UpdatePurchaseAttributes {
  draft_order_id: number
  shipping_address?: ShippingAddress
  billing_address?: BillingAddress
  shipping_rate_id?: number
  line_items?: LineItem[]
  code?: string
}

export interface CompletePurchaseAttributes {
  draft_order_id: number
  payment_method_id?: string
}

export enum CreditCardBrand {
  visa = 'visa',
  mastercard = 'mastercard',
  american_express = 'american express',
  diners_club = 'diners club',
  discover = 'discover',
  jcb = 'jcb',
  unionpay = 'unionpay',
  unknown = 'unknown',
}

class PaymentService extends BaseService {
  constructor() {
    super({ configPath: 'services.commerce' })
  }

  /*
   * Initiates a draft order to start the purchase process for a subscription.
   *
   * TODO: is this used on mobile?
   */
  async initiate(attributes: InitiatePurchaseAttributes): Promise<PurchaseResponse> {
    return await this.requestResource('purchaseInitiate', {
      method: 'POST',
      data: attributes,
    })
  }

  async update(attributes: UpdatePurchaseAttributes): Promise<PurchaseResponse> {
    return await this.requestResource('purchaseUpdate', {
      method: 'POST',
      data: attributes,
    })
  }

  async complete(attributes: CompletePurchaseAttributes): Promise<PurchaseResponse> {
    return await this.requestResource('purchaseComplete', {
      method: 'POST',
      data: attributes,
    })
  }

  /** BEGIN v2 endpoints **/
  async initiateV2(attributes: InitiatePurchaseAttributes, loggedIn: boolean): Promise<PurchaseResponse> {
    const resource = loggedIn ? 'purchaseAuthInitiateV2' : 'purchaseInitiateV2'
    let options = {
      method: 'POST',
      data: attributes,
    }

    if (!loggedIn) {
      options['tokenType'] = 'none'
    }

    return await this.requestResource(resource, options)
  }

  async updateV2(attributes: UpdatePurchaseAttributes, loggedIn: boolean): Promise<PurchaseResponse> {
    const resource = loggedIn ? 'purchaseAuthUpdateV2' : 'purchaseUpdateV2'
    let options = {
      method: 'POST',
      data: attributes,
    }

    if (!loggedIn) {
      options['tokenType'] = 'none'
    }

    return await this.requestResource(resource, options)
  }

  async completeV2(attributes: CompletePurchaseAttributes): Promise<PurchaseResponse> {
    return await this.requestResource('purchaseAuthCompleteV2', {
      method: 'POST',
      data: attributes,
    })
  }
  /** END v2 endpoints **/

  async paymentSetup(): Promise<{
    response_code: number
    message: string
    data: {
      id: string
      client_secret: string
      status: PaymentSetupStatus
      authentication_url?: string
      post_authentication_url?: string
    }
  }> {
    return await this.requestResource('paymentSetup', { method: 'POST' })
  }

  async payment(): Promise<GetPaymentResponse> {
    return await this.requestResource('paymentGet')
  }

  async setDefaultPayment(payment_method_id: string): Promise<{
    response_code: number
    message: string
  }> {
    return await this.requestResource('paymentSetDefault', {
      method: 'POST',
      data: {
        payment_method_id,
      },
    })
  }

  async validateDiscount(
    code: string,
    line_items: LineItem[] = [],
  ): Promise<{
    response_code: number
    message: string
    data: DiscountSummary
  }> {
    const body = {
      code,
      line_items,
    }
    const response = await this.requestResource('validateDiscount', {
      method: 'POST',
      data: body,
    })

    const { response_code, message } = response

    if (response_code) {
      throw message
    } else {
      return response
    }
  }

  async getDiscount(codes: string[]): Promise<Discount[]> {
    const { response_code, message, data } = await this.requestResource('getDiscount', {
      tokenType: 'none',
      method: 'GET',
      queryParams: {
        code: codes,
      },
      queryOptions: { arrayFormat: 'repeat' },
    })

    if (response_code) {
      throw message
    } else {
      return compact(data)
    }
  }

  async activateSubscription(code: string): Promise<{ success: boolean; message: string }> {
    const body = { code }
    return await this.requestResource('subscriptionActivate', {
      method: 'POST',
      data: body,
    })
  }

  async getShippingZones() {
    return await this.requestResource('getShippingZones', {
      tokenType: 'none',
      method: 'GET',
    })
  }
}

export default PaymentService
