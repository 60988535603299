import { call, put, select } from 'redux-saga/effects'
import AuthSelectors from '../../app/modules/auth/selectors'
import { decodeToken } from '../../TokensService'
import sha256 from 'crypto-js/sha256'
import md5 from 'crypto-js/md5'

export function loginRequired(saga) {
  return function* (...args) {
    const loggedIn = yield select(state => AuthSelectors.loggedIn(state))

    if (loggedIn) {
      yield call(saga, ...args)
    }
  }
}

export function routineSaga({
  filters = [],
  routine,
  request,
  afterSuccess = () => {},
  onError = null,
  internal = false,
}) {
  return function* (action) {
    const { payload = {} } = action
    const { onFailure, onFulfill, onSuccess } = payload
    try {
      for (const filter of filters) {
        const allow = yield call(filter, action)
        if (!allow) {
          return
        }
      }

      yield put(routine.request(action.payload))
      const returnValue = yield request(action)
      yield put(routine.success(returnValue))
      if (afterSuccess) {
        yield call(afterSuccess, returnValue)
      }
      if (onSuccess) {
        yield call(onSuccess, returnValue)
      }
    } catch (error) {
      if (onError) {
        yield call(onError, error)
      } else {
        const { errors } = error?.response?.data || {}
        const internalError = error?.internal || internal

        if (onFailure) {
          let message = error?.response?.data?.message
          if (typeof error === 'string') {
            message = error
          }
          if (!message && error?.message) {
            message = error?.message
          }
          yield call(onFailure, message)
        }

        if (errors) {
          yield put(routine.failure({ error: errors, internal: internalError }))
        } else {
          yield put(routine.failure({ error, internal: internalError }))
        }
      }
    } finally {
      if (onFulfill) {
        yield call(onFulfill)
      }
      yield put(routine.fulfill())
    }
  }
}

export function sendTo(actionFunction, payloadOptions = {}, generator = undefined) {
  return function* ({ payload }) {
    if (generator) {
      yield generator
    }
    yield put(actionFunction({ ...payload, ...payloadOptions }))
  }
}

export function loginBetaUser(saga: any) {
  return function* () {
    let user = 'not-found'

    const idToken = yield select(state => AuthSelectors.idToken(state))
    if (idToken) {
      user = decodeToken(idToken).email
    }

    const payload = {
      appName: 'pvolve-web',
      instanceId: sha256(user).toString(),
      context: {
        sessionId: md5(user).toString(),
        user,
        platform: 'web',
      },
      flags: ['phaseAndFunctionBeta', 'phaseAndFunctionSeries'],
    }
    yield call(saga, payload)
  }
}

export function getOnboardingUserAttributes(saga: any) {
  return function* () {
    let tokenAttributes = 'not-found'

    const idToken = yield select(state => AuthSelectors.idToken(state))
    if (idToken) {
      tokenAttributes = decodeToken(idToken)
    }

    const basePayload = {
      appName: 'pvolve-web',
      instanceId: sha256(tokenAttributes).toString(),
      context: {
        userId: tokenAttributes?.email,
      },
    }

    const payload = {
      onboardingD2C: {
        ...basePayload,
        flags: ['onboardingD2C'],
      },
      onboardingBetaUsers: {
        ...basePayload,
        flags: ['onboardingBetaUsers'],
      },
      tokenAttributes,
    }

    yield call(saga, payload)
  }
}

export default {}
