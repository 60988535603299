import Actions from './actions'
import { handleActionsImmer } from '../../utils'

export const initialState = {
  phaseAndFunction: {},
}

const {
  flags: { phaseAndFunction },
} = Actions

export default handleActionsImmer(
  {
    [phaseAndFunction.success]: (draft, data) => {
      if (data) {
        draft.phaseAndFunction = data
      }
    },
  },
  initialState,
  {
    phaseAndFunction,
  },
)
