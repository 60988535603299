import { createActions } from 'redux-actions'
import { DefaultRoutine } from '@pvolve/sdk/src/app/utils'
import { DefaultRoutineActions } from '../../utils'

interface SeriesActions {
  series: {
    enrolled: DefaultRoutineActions
    join: DefaultRoutineActions
    leave: DefaultRoutineActions
    seriesFeatured: DefaultRoutineActions
    loadHighlightedSeries: DefaultRoutineActions
    loadAllSeriesTargeted: DefaultRoutineActions
  }
}

const Actions = <SeriesActions>(<unknown>createActions({
  SERIES: {
    ENROLLED: DefaultRoutine,
    JOIN: DefaultRoutine,
    LEAVE: DefaultRoutine,
    SERIES_FEATURED: DefaultRoutine,
    LOAD_HIGHLIGHTED_SERIES: DefaultRoutine,
    LOAD_ALL_SERIES_TARGETED: DefaultRoutine,
  },
}))

export default Actions
