// inpsired by:
//    https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
//    https://gist.github.com/lukebrandonfarrell/d0d410042ca02c841f782685fcee6c2d

import React, { DependencyList } from 'react'
import { castArray } from 'lodash'
import { Store, AnyAction } from 'redux'
import { Action } from 'redux-actions'

const initialState = {
  type: null,
  payload: null,
  meta: null,
  error: false,
}

export const ReduxEffectReducer = (state = initialState, action: Action<any>) => {
  return { ...action }
}

const config: {
  store?: Store<any, AnyAction>
  React?: React
} = {}

export function configureHook(React: React, store: Store<any, AnyAction>) {
  config.store = store
  config.React = React
}

/**
 * Redux Event Hook for React
 */
export function useReduxEffect(effect: (action: Action<any>) => void, type: string | string[], deps: DependencyList) {
  const { useRef, useEffect } = config.React
  const currentAction = useRef(null)
  const { store: store } = config

  const handleChange = () => {
    const state = store.getState()
    const { action } = state

    const { current } = currentAction

    const types = castArray(type).map(action => action.toString())

    if (current !== action && types.includes(action.type)) {
      currentAction.current = action
      if (action) {
        effect(action)
      } else {
        console.error('useReduxEffect is not installed properly: action reducer not found')
      }
    }
  }

  useEffect(() => {
    if (store) {
      const unsubscribe = store.subscribe(handleChange)
      return () => unsubscribe()
    } else {
      console.error('useReduxEffect is not installed properly: store not found')
    }
  }, deps)
}
