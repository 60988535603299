import { get } from 'lodash'
import jwtDecode from 'jwt-decode'
import BaseService from './BaseService'

export const ERROR_CODE_TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND'

export default class TokensService extends BaseService {
  constructor() {
    super({})
  }

  decodedIdToken() {
    return this.id ? this.decode(this.id) : null
  }

  async userInfo() {
    await this.freshTokens()
    return this.decodedIdToken()
  }

  async userId() {
    return get(await this.userInfo(), 'sub')
  }

  decode(jwt) {
    return decodeToken(jwt)
  }

  async refreshTokens() {
    if (this.refresh) {
      const token = await this.core.api.auth.refresh(this.refresh)
      this.updateTokens(token)
    } else {
      await this.clear()
      throw {
        code: ERROR_CODE_TOKEN_NOT_FOUND,
        message: 'Refresh token not found',
      }
    }
  }

  updateTokens(tokens) {
    let mapToken = {
      id: tokens.id,
      access: tokens.access,
    }

    if (tokens.refresh) {
      mapToken = {
        ...mapToken,
        refresh: tokens.refresh,
      }
    }

    this._tokenStore.updateTokens(mapToken)
  }

  /**
   *
   * Make sure id token is valid and if it isn't then refresh it
   */
  async freshTokens() {
    if (this.expired()) {
      await this.refreshTokens()
    }

    return this
  }

  async clear() {
    await this._tokenStore.clear()
  }

  expired() {
    const id = this.decodedIdToken()
    return !id || tokenExpired(id)
  }

  set tokenStore(tokenStore) {
    this._tokenStore = tokenStore
  }

  get id() {
    return this._tokenStore.id
  }

  get refresh() {
    return this._tokenStore.refresh
  }

  get access() {
    return this._tokenStore.access
  }
}

export function decodeToken(token) {
  return jwtDecode(token)
}

/**
 * Checks to see if a token is expired
 */
export function tokenExpired({ exp }) {
  const currentTimestamp = Math.floor(Date.now() / 1000)
  return currentTimestamp >= exp
}
