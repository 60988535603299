export const USER_COHORT = {
  d2c: 'd2c',
  pvolve: 'pvolve',
  trialist: 'trialist',
}

export const ENTITLEMENT_SOURCE = {
  optum: 'optum',
  optcom: 'd2c',
}

export const getUserCohortsString = () =>
  Object.values(USER_COHORT).reduce((prev: string, current: string) => `${prev},cohort${current}`, '')
