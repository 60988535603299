import BaseService from './BaseService'

/**
 * uses attributes api to store and retrieve the user account
 *
 * future work:
 *  may handle conflict resolution
 *
 * @example
 *    // using CoreService (recommended)
 *    import Core from '@pvolve/sdk';
 *
 *    const { value: account } = await Core.api.account.get();
 *    console.log(account); // should be something like { birthdate: }
 *
 *    await Core.api.account.save({ videos: [] });
 */

class FeatureFlagService extends BaseService {
  constructor() {
    super({ configPath: 'services.contentV2' })
  }

  async featureFlagByName(payload: any) {
    try {
      return await this.requestResource('featureFlagByName', {
        method: 'POST',
        tokenType: 'optional',
        data: {
          appName: payload.appName,
          instanceId: payload.instanceId,
          context: payload.context,
          flags: payload.flags,
        },
      })
    } catch (ex) {
      console.error(ex)
      throw Error(ex)
    }
  }
}

export default FeatureFlagService
