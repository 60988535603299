import { Action } from 'redux-actions'
import Pvolve from '@pvolve/sdk'
import { fork, put, select, take } from 'redux-saga/effects'

import ConfigSelectors from './selectors'
import ConfigActions from './actions'
import { CONFIG_UPDATE_ACTION } from '../../../redux/ReduxConfigStore'

export class ConfigSagas {
  *init() {
    let currentEnvironment: string
    while (true) {
      if (Pvolve.api.config) {
        Pvolve.api.config.freshConfig()
      }

      yield take((action: Action<any>) => !currentEnvironment || action.type === CONFIG_UPDATE_ACTION)

      const config = yield select(ConfigSelectors.root)
      if (config?.environment && currentEnvironment !== config?.environment) {
        currentEnvironment = config.environment
        yield put(ConfigActions.config.loaded(config))
      }
    }
  }
}

export const saga = new ConfigSagas()

export default function* combined() {
  yield fork(saga.init)
}
