import { put, takeEvery, select } from 'redux-saga/effects'
import { routineSaga, loginRequired, sendTo } from '../../utils/sagas'
import Actions from '../../actions'
import { ENTITLEMENT_SOURCE, getUserCohortsString } from '../auth/constants'
import Pvolve from '@pvolve/sdk'
import { isEmpty } from 'lodash'
import { ISeriesFields } from '../../../models/contentful_types'

const {
  auth: { updateUserAttributes, login, signup },
  series: { enrolled, join, leave, seriesFeatured, loadHighlightedSeries, loadAllSeriesTargeted },
} = Actions

class SeriesSaga {
  *init() {
    yield takeEvery(enrolled.trigger, loginRequired(saga.enrolled))
    yield takeEvery(join.trigger, saga.join)
    yield takeEvery(leave.trigger, saga.leave)
    yield takeEvery(seriesFeatured.trigger, saga.seriesFeatured)
    yield takeEvery(loadHighlightedSeries.trigger, saga.loadHighlightedSeries)
    yield takeEvery(loadAllSeriesTargeted.trigger, saga.loadAllSeriesTargeted)
  }

  enrolled = routineSaga({
    routine: enrolled,
    *request() {
      const { series: listSeriesEnrolled } = yield Pvolve.api.workout.listEnrolled()
      return listSeriesEnrolled
    },
  })

  join = routineSaga({
    routine: join,
    *request({ payload: { seriesId } }) {
      yield Pvolve.api.workout.enroll(seriesId)
    },
    *afterSuccess() {
      const responseSuccess = yield Pvolve.api.account.getUserAttributes()

      if (responseSuccess?.object) {
        yield put(updateUserAttributes.trigger(responseSuccess.object))
      }
    },
  })

  leave = routineSaga({
    routine: leave,
    *request({ payload: { seriesId } }) {
      yield Pvolve.api.workout.leave(seriesId)
    },
  })

  seriesFeatured = routineSaga({
    routine: seriesFeatured,
    *request() {
      const response = yield Pvolve.api.contentV2.seriesFeaturedList()
      return response
    },
  })

  loadHighlightedSeries = routineSaga({
    routine: loadHighlightedSeries,
    *request() {
      const userCohort: string = yield select(state =>
        !!state.account ? state.account.userCohort : state.auth.user?.user_cohort,
      )
      const options = { tags: `cohort${userCohort}`, operator: 'all' }
      const response = yield Pvolve.api.contentV2.content.list('series_featured', options)
      return response
    },
  })

  loadAllSeriesTargeted = routineSaga({
    routine: loadAllSeriesTargeted,
    *request() {
      let response = []
      const userCohort: string = yield select(state =>
        !!state.account ? state.account.userCohort : state.auth.user?.user_cohort,
      )
      const hasTargetedContent = !isEmpty(ENTITLEMENT_SOURCE[userCohort])
      if (hasTargetedContent) {
        const options = { tags: `cohort${userCohort}`, excludeTags: 'permutation', operator: 'all' }
        const targetedSeries = yield Pvolve.api.contentV2.content.list('series', options)
        response = [...targetedSeries?.items]
      }
      const options = { tags: getUserCohortsString(), operator: 'in' }
      const defaultSeries = yield Pvolve.api.contentV2.content.list('series', options)
      response = [...response, ...defaultSeries?.items]
      return response
    },
  })
}

export const saga = new SeriesSaga()

export default function* combined() {
  yield takeEvery(
    ['persist/REHYDRATE', join.success, leave.success, login.success, signup.success],
    sendTo(enrolled.trigger),
  )
  yield takeEvery(enrolled.trigger, loginRequired(saga.enrolled))
  yield takeEvery(seriesFeatured.trigger, saga.seriesFeatured)
  yield takeEvery(join.trigger, saga.join)
  yield takeEvery(leave.trigger, saga.leave)
  yield takeEvery(loadHighlightedSeries.trigger, saga.loadHighlightedSeries)
  yield takeEvery(loadAllSeriesTargeted.trigger, saga.loadAllSeriesTargeted)
}
