import { createActions } from 'redux-actions'
import { DefaultRoutine, DefaultRoutineActions, BasicActionFunction } from '@pvolve/sdk/src/app/utils'

interface SubscriptionsActions {
  subscriptions: {
    load: DefaultRoutineActions
    select: BasicActionFunction
    loadProducts: DefaultRoutineActions
    loadProduct: DefaultRoutineActions
    purchaseFlow: DefaultRoutineActions<{
      sku: string
      payment_method_id: string
    }>
    purchase: DefaultRoutineActions
    submitReceipt: DefaultRoutineActions
    purchaseUpdate: BasicActionFunction
    purchaseError: BasicActionFunction
    entitlementsNotAdded: BasicActionFunction
    loadReceipt: DefaultRoutineActions
    restorePurchase: DefaultRoutineActions
    bypass: BasicActionFunction
    loadPaymentMethods: DefaultRoutineActions
    confirmPaymentSetup: DefaultRoutineActions
    updatePlan: DefaultRoutineActions<{
      billing_address: SubscriptionBillingAddress
      new_sku?: string
      promo_code?: string
    }>
    cancelSubscription: DefaultRoutineActions
    setActivationCode: DefaultRoutineActions<{ code: string }>
    activation: {
      verify: DefaultRoutineActions<{ provider: string; member_id: string }>
    }
    activateSubscription: DefaultRoutineActions<{ code: string }>
    reactivate: DefaultRoutineActions<{ subscription_id: string }>
    getDiscounts: DefaultRoutineActions<{ codes: string[] }>
    getDefaultPromoDiscount: DefaultRoutineActions
  }
}

const Actions = <SubscriptionsActions>(<unknown>createActions({
  SUBSCRIPTIONS: {
    LOAD: DefaultRoutine,
    SELECT: undefined,
    LOAD_PRODUCTS: DefaultRoutine,
    LOAD_PRODUCT: DefaultRoutine,
    PURCHASE_FLOW: DefaultRoutine,
    PURCHASE: DefaultRoutine,
    SUBMIT_RECEIPT: DefaultRoutine,
    PURCHASE_UPDATE: undefined,
    PURCHASE_ERROR: undefined,
    ENTITLEMENTS_NOT_ADDED: undefined,
    LOAD_RECEIPT: DefaultRoutine,
    RESTORE_PURCHASE: DefaultRoutine,
    BYPASS: undefined,
    LOAD_PAYMENT_METHODS: DefaultRoutine,
    CONFIRM_PAYMENT_SETUP: DefaultRoutine,
    UPDATE_PLAN: DefaultRoutine,
    CANCEL_SUBSCRIPTION: DefaultRoutine,
    SET_ACTIVATION_CODE: DefaultRoutine,
    ACTIVATION: { VERIFY: DefaultRoutine },
    ACTIVATE_SUBSCRIPTION: DefaultRoutine,
    REACTIVATE: DefaultRoutine,
    GET_DISCOUNTS: DefaultRoutine,
    GET_DEFAULT_PROMO_DISCOUNT: DefaultRoutine,
  },
}))

export default Actions
