import { createSelector } from 'reselect'
import { differenceInDays, format, isAfter, isEqual, subDays } from 'date-fns'
import { PHASES } from './constants'
import Workouts from '../workouts/selectors'
import { PF_PHASES_DATE_FORMAT, INTERNAL_DATE_FORMAT, formatInternalDate } from '../../utils'
import { IState, ContentSelectors, IWorkoutTypes, IContentIndex } from '../../../redux/selectors'
import { IWorkout } from '../../../models/contentful_types'

const phases = (state: IState) => state?.phases.data
const featureFlags = (state: IState) => state?.flags?.phaseAndFunction
const seriesEnrolledHistory = (state: IState) => state?.series?.enrolled

const pnfSerieId = createSelector(
  featureFlags,
  featureflag => featureflag?.phaseAndFunctionSeries?.variant?.payload?.series[0],
)

const pnfContentSerie = createSelector(
  pnfSerieId,
  ContentSelectors.series.indexedBy.id,
  (pnfSerieId, contentSeries) => contentSeries[pnfSerieId],
)

const pnfSerieHistoryData = createSelector(
  seriesEnrolledHistory,
  pnfSerieId,
  (seriesEnrolledHistory, pnfSerieId) => seriesEnrolledHistory[pnfSerieId],
)

const questionnaireId = createSelector(
  pnfContentSerie,
  pnfContentSerie => pnfContentSerie?.fields?.questionnaire?.sys?.id,
)

const questionnaires = createSelector(phases, phases => phases?.questionnaires)

const phasesQuestionnaire = createSelector(
  questionnaires,
  questionnaireId,
  (questionnaires, questionnaireId) =>
    questionnaires?.find(questionnaire => questionnaire.id === questionnaireId)?.responses,
)

const phasesAttr = createSelector(phasesQuestionnaire, phases =>
  phases?.reduce((obj, item) => {
    obj[item.question_id] = item.value
    return obj
  }, {}),
)

const cycleLength = createSelector(phasesAttr, (phases: { cycle_length: any }) => phases?.cycle_length)

const periodLength = createSelector(phasesAttr, (phases: { period_length: any }) => phases?.period_length)

const lastPeriodDate = createSelector(phasesAttr, (phases: { last_period_date: any }) => phases?.last_period_date)

const firstOvulatoryDay = createSelector(cycleLength, cycle => Number(cycle) - 14)

const lastOvulatoryDay = createSelector(firstOvulatoryDay, firtsDay => firtsDay + 2)

const firtsFollicularDay = createSelector(periodLength, period => Number(period) + 1)

const lastFollicularDay = createSelector(firstOvulatoryDay, firstDay => firstDay - 1)

const firstLutealDay = createSelector(lastOvulatoryDay, lastDay => lastDay + 1)

const currentPhases = createSelector(
  phasesQuestionnaire,
  cycleLength,
  lastPeriodDate,
  periodLength,
  firtsFollicularDay,
  lastFollicularDay,
  firstOvulatoryDay,
  lastOvulatoryDay,
  firstLutealDay,
  (phasesQuestionnaire, cycle, lastDay, period, folFirstD, folLastD, ovFirstD, ovLastD, lutFirstD) => {
    if (!phasesQuestionnaire) {
      return
    }
    const currentDate = new Date()
    const lastDayDate = new Date(lastDay)
    const firstPeriodDay = 1
    const getDays = (last: number, first: number) => last - first + 1
    const getDate = (phaseDate: number) => format(subDays(currentDate, diffDays - phaseDate), PF_PHASES_DATE_FORMAT)
    let diffDays = differenceInDays(currentDate, lastDayDate) + 1
    let currentPhase: string
    let currentPhaseD: number

    while (diffDays > cycle) {
      diffDays = diffDays - cycle
    }

    if (diffDays <= period) {
      currentPhase = PHASES.MENSTRUAL
      currentPhaseD = getDays(diffDays, firstPeriodDay)
    } else if (diffDays >= folFirstD && diffDays <= folLastD) {
      currentPhase = PHASES.FOLLICULAR
      currentPhaseD = getDays(diffDays, folFirstD)
    } else if (diffDays >= ovFirstD && diffDays <= ovLastD) {
      currentPhase = PHASES.OVULATORY
      currentPhaseD = getDays(diffDays, ovFirstD)
    } else {
      currentPhase = PHASES.LUTEAL
      currentPhaseD = getDays(diffDays, lutFirstD)
    }

    const phases = [
      {
        name: PHASES.MENSTRUAL,
        days: period,
        start: {
          day: firstPeriodDay,
          date: getDate(firstPeriodDay),
        },
        end: {
          day: period,
          date: getDate(period),
        },
      },
      {
        name: PHASES.FOLLICULAR,
        days: getDays(folLastD, folFirstD),
        start: {
          day: folFirstD,
          date: getDate(folFirstD),
        },
        end: {
          day: folLastD,
          date: getDate(folLastD),
        },
      },
      {
        name: PHASES.OVULATORY,
        days: getDays(ovLastD, ovFirstD),
        start: {
          day: ovFirstD,
          date: getDate(ovFirstD),
        },
        end: {
          day: ovLastD,
          date: getDate(ovLastD),
        },
      },
      {
        name: PHASES.LUTEAL,
        days: getDays(cycle, lutFirstD),
        start: {
          day: lutFirstD,
          date: getDate(lutFirstD),
        },
        end: {
          day: cycle,
          date: getDate(cycle),
        },
      },
    ]

    const hasNegativePhase = phases.some(phase => phase.days <= 0)

    return {
      cycle,
      current: currentPhase,
      currentDay: diffDays,
      currentPhaseDay: currentPhaseD,
      currentDate: format(currentDate, INTERNAL_DATE_FORMAT),
      phases: phases,
      hasNegativePhase: hasNegativePhase,
    }
  },
)

const pnfWorkoutsCarousel = createSelector(
  pnfSerieHistoryData,
  currentPhases,
  ContentSelectors.workout.indexedBy.id,
  Workouts.completedHistoryByWorkoutId,
  Workouts.historyByDate,
  (pnfSerieHistoryData, currentPhases, contentWorkouts, completedHistoryByWorkoutId, todayHistory) => {
    if (!currentPhases || !pnfSerieHistoryData || !completedHistoryByWorkoutId) {
      return
    }

    let chaptersCarousel = []

    const chapters = pnfSerieHistoryData.series_detail.chapters
    const workoutsState = pnfSerieHistoryData.series_state.workouts
    let { current, currentDay, currentPhaseDay, phases } = currentPhases
    const startCycle = new Date(phases[0].start.date)
    const finishedToday = todayHistory[formatInternalDate(new Date())]?.length > 0

    switch (current) {
      case PHASES.MENSTRUAL:
        chaptersCarousel.push(
          proccessItems(
            getWorkoutsByPhase(
              startCycle,
              chapters[0].workout_ids,
              workoutsState,
              contentWorkouts,
              completedHistoryByWorkoutId,
              phases[0].name,
            ),
            phases[0].days,
            current === PHASES.MENSTRUAL ? currentPhaseDay : 0,
            finishedToday,
          ),
        )
      case PHASES.FOLLICULAR:
        chaptersCarousel.push(
          proccessItems(
            getWorkoutsByPhase(
              startCycle,
              chapters[1].workout_ids,
              workoutsState,
              contentWorkouts,
              completedHistoryByWorkoutId,
              phases[1].name,
            ),
            phases[1].days,
            current === PHASES.FOLLICULAR ? currentPhaseDay : 0,
            finishedToday,
          ),
        )
      case PHASES.OVULATORY:
        chaptersCarousel.push(
          proccessItems(
            getWorkoutsByPhase(
              startCycle,
              chapters[2].workout_ids,
              workoutsState,
              contentWorkouts,
              completedHistoryByWorkoutId,
              phases[2].name,
            ),
            phases[2].days,
            current === PHASES.OVULATORY ? currentPhaseDay : 0,
            finishedToday,
          ),
        )
      case PHASES.LUTEAL:
        chaptersCarousel.push(
          proccessItems(
            getWorkoutsByPhase(
              startCycle,
              chapters[3].workout_ids,
              workoutsState,
              contentWorkouts,
              completedHistoryByWorkoutId,
              phases[3].name,
            ),
            phases[3].days,
            current === PHASES.LUTEAL ? currentPhaseDay : 0,
            finishedToday,
          ),
        )

      default:
        break
    }

    chaptersCarousel = chaptersCarousel.flat().map(obj => ({ cycleDay: currentDay++, ...obj }))

    if (finishedToday && chaptersCarousel[0].isCurrentWorkout === true) {
      chaptersCarousel.shift()
    }

    return chaptersCarousel
  },
)

function getWorkoutsByPhase(
  initCycle: Date,
  workoutsByPhase: any[],
  workoutsState,
  contentWorkouts: IContentIndex<IWorkout>,
  completedHistoryByWorkoutId: any,
  workoutPhase: string,
) {
  let workoutsResult = []

  for (const workoutId of workoutsByPhase) {
    const workoutState = workoutsState.find(e => workoutId === e.workout_id)
    const workoutCompleted = completedHistoryByWorkoutId[workoutId]?.find(
      e =>
        e.is_completed &&
        (isAfter(new Date(e.completed_at), initCycle) || isEqual(new Date(e.completed_at), initCycle)),
    )

    const workoutType: string = contentWorkouts[workoutId]?.fields?.type

    workoutsResult.push({
      isCompleted: workoutCompleted !== undefined,
      isRestType: workoutType === IWorkoutTypes.rest,
      type: workoutType,
      phase: workoutPhase,
      ...workoutState,
    })
  }

  return workoutsResult
}

const proccessItems = (
  workoutsByPhase: any[],
  totalElements: number,
  originalIndex: number,
  finishedToday: boolean,
) => {
  const currentIndex = originalIndex > 0 ? originalIndex - 1 : originalIndex
  if (originalIndex > 0) {
    workoutsByPhase[currentIndex].isCurrentWorkout = true
  }

  let result = workoutsByPhase.slice(currentIndex, totalElements)

  let notCompletedBefore = workoutsByPhase
    .slice(0, currentIndex)
    .filter(element => !element.isCompleted && element.type === undefined)
  let notCompletedAfter = workoutsByPhase
    .slice(currentIndex)
    .filter(element => !element.isCompleted && element.type === undefined)

  let workoutsNotCompleted = [...notCompletedAfter, ...notCompletedBefore]
  const untypedWorkouts = workoutsByPhase.filter(element => element.type === undefined)

  result.forEach((element, idx) => {
    if (element.isCurrentWorkout && finishedToday && element.isCompleted) {
      return
    }

    if (element.type === undefined) {
      if (workoutsNotCompleted.length > 0) {
        result[idx] = workoutsNotCompleted.shift()
      } else {
        result[idx] = untypedWorkouts[Math.floor(Math.random() * (untypedWorkouts.length - 1))]
      }
    }
  })

  return result
}

export default {
  currentPhases,
  pnfWorkoutsCarousel,
}
