import { call, put, takeEvery, takeLatest, select, take, fork, race, delay } from 'redux-saga/effects'

import Pvolve from '@pvolve/sdk'
import Actions, { authSuccessActions } from './actions'
import ConfigSelectors from '../config/selectors'
import Selectors from './selectors'
import SubSelectors from '../subscriptions/selectors'
import { getOnboardingUserAttributes, loginRequired, routineSaga, sendTo } from '@pvolve/sdk/src/app/utils/sagas'
import { getUserCohort } from './utils'
import AuthAdapter from './AuthAdapter'
//import { Keyboard } from 'react-native';
//import { updateBugsnagUser } from 'src/bugsnag';
import { Action } from 'redux-actions'
import { get, isEmpty } from 'lodash'
import { hasAnsweredOnboarding } from '@pvolve/sdk/src/app/modules/questionnaire/utils'

import { TOKEN_UPDATE_ACTION } from '../../../redux/ReduxTokenStore'

const ENTITLEMENTS_RETRY_DELAY = 5000
const ENTITLEMENTS_MAX_TRIES = 20

const {
  auth: {
    changePassword,
    federated,
    load,
    logout,
    login,
    signup,
    refresh,
    deactivate,
    update,
    acceptWaiver,
    forgotPassword,
    resendConfirmationCode,
    confirmForgotPassword,
    clearSignupErrors,
    changeDefaultStation,
    incrementExperience,
    processOauthCode,
    watchForEntitlements,
    submitQuestionnaireAnswers,
    getTokenAttributes,
    getAllAttrs,
  },
} = Actions

class AuthSaga {
  submitQuestionnaireAnswers = routineSaga({
    routine: submitQuestionnaireAnswers,
    *request({ payload: { questionnaireResponse } }) {
      yield Pvolve.api.account.saveQuestionnairesAttributes(questionnaireResponse)
      const response = yield Pvolve.api.account.getQuestionnairesAttributes()
      return response?.object?.questionnaires
    },
  })

  changePassword = routineSaga({
    routine: changePassword,
    *request({ payload: { oldPassword, newPassword } }) {
      yield Pvolve.api.cognito.changePassword(oldPassword, newPassword)
    },
  })

  federated = routineSaga({
    routine: federated,
    *request({ payload: { provider, platform, signUp } }) {
      const { tokens: Tokens } = Pvolve.api
      const tokens = yield AuthAdapter.signInWithOAuth(provider, platform)
      Tokens.updateTokens(tokens)
      return tokens
    },
  })

  load = routineSaga({
    routine: load,
    *request() {
      return yield Pvolve.api.account.getUserAttributes()
    },
    *afterSuccess() {
      yield put(getTokenAttributes.trigger())
    },
  })

  login = routineSaga({
    routine: login,
    *request({ payload: { email, password, logInAsTokens } }) {
      if (logInAsTokens) {
        const tokens = {
          id: logInAsTokens.id_token,
          access: logInAsTokens.access_token,
          refresh: logInAsTokens.refresh_token,
          expiresIn: logInAsTokens.expires_in,
        }
        Pvolve.api.tokens.updateTokens(tokens)
        return tokens
      }
      const { tokens } = yield Pvolve.api.auth.login(email, password)
      Pvolve.api.tokens.updateTokens(tokens)
      return tokens
    },
    *afterSuccess({ onSuccess }) {
      if (onSuccess) {
        yield call(onSuccess)
      }
    },
  })

  signup = routineSaga({
    routine: signup,
    internal: true,
    *request({ payload: { email, password, givenName, firstName, familyName, lastName, optIn } }) {
      const { auth: Auth, tokens: Tokens } = Pvolve.api
      const given_name = givenName || firstName
      const family_name = familyName || lastName
      const activation = yield select(SubSelectors.partnerActivation.activation)

      const clientMetadata = {
        optOut: String(!optIn),
      }
      let userAttributes = {}

      if (activation?.data?.member_id) {
        clientMetadata['member_id'] = activation.data.member_id
      }
      if (activation?.provider) {
        clientMetadata['provider'] = activation.provider
      }

      if (given_name) {
        userAttributes['given_name'] = given_name
      }

      if (family_name) {
        userAttributes['family_name'] = family_name
      }

      const { tokens } = yield Auth.register(email, password, !optIn, userAttributes, clientMetadata)
      Tokens.updateTokens(tokens)

      return { ...tokens }
    },
  })

  refresh = routineSaga({
    routine: refresh,
    internal: true,
    *request() {
      const { refresh } = yield select(Selectors.tokens)
      const { auth: Auth, tokens: Tokens } = Pvolve.api
      const tokens = yield Auth.refresh(refresh)
      Tokens.updateTokens(tokens)

      return { ...tokens }
    },
    *afterSuccess({ onSuccess }) {
      if (onSuccess) {
        yield call(onSuccess)
      }
    },
  })

  watchForEntitlements = routineSaga({
    routine: watchForEntitlements,
    *request() {
      let tries = 0
      let entitlements: string[] = []
      let user = yield select(Selectors.user)

      do {
        yield Pvolve.api.tokens.refreshTokens()
        yield delay(ENTITLEMENTS_RETRY_DELAY)

        user = yield select(Selectors.user)
        entitlements = yield select(Selectors.entitlements)
      } while (isEmpty(entitlements) && user && tries++ < ENTITLEMENTS_MAX_TRIES)
    },
  })

  logout = routineSaga({
    routine: logout,
    *request() {
      const { tokens: Tokens } = Pvolve.api
      Tokens.clear()
      // TODO React Native this doesn't belong here
      //Keyboard.dismiss(); TODO
    },
  })

  deactivate = routineSaga({
    routine: deactivate,
    *request() {
      const response = yield Pvolve.api.account.deactivate()
      return response
    },
  })

  update = routineSaga({
    routine: update,
    *request({
      payload: {
        birthday,
        email,
        firstName,
        gender,
        lastName,
        phone,
        confirmation_shown,
        feed_fm_disabled,
        feed_fm_playing,
        closed_captions,
        wifi_only,
      },
    }) {
      const current = yield Pvolve.api.account.getUserAttributes()
      const response = yield Pvolve.api.account.saveUserAttributes(current.version + 1, {
        birthday,
        email,
        firstName,
        gender,
        lastName,
        phone,
        confirmation_shown,
        feed_fm_disabled,
        feed_fm_playing,
        closed_captions,
        wifi_only,
      })

      if (!response.object) {
        response.object = {}
      }

      if (response.version) {
        response.object.is_ftu = Boolean(current?.object?.is_ftu)
        return response
      }
    },
  })

  acceptWaiver = routineSaga({
    routine: acceptWaiver,
    *request() {
      const current = yield Pvolve.api.account.getUserAttributes()
      const response = yield Pvolve.api.account.saveUserAttributes(current.version + 1, {
        waiver: new Date().toISOString(),
      })

      if (response.version) {
        return response
      }
    },
  })

  forgotPassword = routineSaga({
    routine: forgotPassword,
    *request({ payload: { email, reset_type = 'forgot' } }) {
      const { cognito: Cognito } = Pvolve.api

      const {
        CodeDeliveryDetails: { Destination },
      } = yield Cognito.forgotPassword(email, reset_type)

      return Destination
    },
  })

  resendConfirmationCode = routineSaga({
    routine: resendConfirmationCode,
    *request({ payload: { email, reset_type = 'forgot' } }) {
      const { cognito: Cognito } = Pvolve.api

      const {
        CodeDeliveryDetails: { Destination },
      } = yield Cognito.resendConfirmationCode(email, reset_type)

      return Destination
    },
  })

  confirmForgotPassword = routineSaga({
    routine: confirmForgotPassword,
    *request({ payload: { email, confirmationCode, newPassword } }) {
      const { cognito: Cognito } = Pvolve.api

      const result = yield Cognito.confirmForgotPassword(email, newPassword, confirmationCode)

      return {
        result,
        email,
        newPassword,
      }
    },
    *afterSuccess({ email, newPassword }) {
      yield put(login.trigger({ email, password: newPassword }))
    },
  })

  clearSignupErrors = routineSaga({
    routine: clearSignupErrors,
    *request() {
      return true
    },
  })

  /* TODO React Native this doesn't belong here unless it is cross platform
  setBugsnagUser = function* () {
    const user = yield select(Selectors.auth.user);
    updateBugsnagUser(user);
  };

  clearBugsnagUser = function* () {
    updateBugsnagUser(null);
  };
  */

  changeDefaultStation = routineSaga({
    routine: changeDefaultStation,
    *request({ payload: { defaultStationId } }) {
      const { account: Account } = Pvolve.api
      const current = yield Account.getUserAttributes()
      return yield Account.saveUserAttributes(current.version + 1, {
        default_station_id: defaultStationId,
      })
    },
  })

  incrementExperience = routineSaga({
    routine: incrementExperience,
    *request() {
      const { account: Account } = Pvolve.api
      const attributes = yield Account.getUserAttributes()
      const version = attributes?.version || 0
      const experience_count = attributes?.object?.experience_count || 0

      const incrementedExperienceCount = experience_count ? experience_count + 1 : 1

      return yield Account.saveUserAttributes(version + 1, {
        experience_count: incrementedExperienceCount,
      })
    },
  })

  processOauthCode = routineSaga({
    routine: processOauthCode,
    *request({ payload: { code, redirectUri } }) {
      const tokens = yield Pvolve.api.cognito.tokensFromCode(code, redirectUri)
      Pvolve.api.tokens.updateTokens(tokens)
    },
  })

  getTokenAttributes = routineSaga({
    routine: getTokenAttributes,
    *request(payload: any) {
      const onboardingReleaseDate: { releaseDate: string } = yield select(ConfigSelectors.onboarding)
      return yield getUserCohort(onboardingReleaseDate, payload)
    },
  })
  getAllAttrs = routineSaga({
    routine: getAllAttrs,
    *request() {
      const allAttributes = yield Pvolve.api.account.getAllAttributes()
      return {
        ...allAttributes,
        hasAnsweredOnboarding: hasAnsweredOnboarding(allAttributes?.questionnaires?.object?.questionnaires),
      }
    },
  });
  *registerTriggerHandlers() {
    const saga = this
    yield takeEvery(getAllAttrs.trigger, loginRequired(saga.getAllAttrs))
    yield takeEvery(watchForEntitlements.trigger, loginRequired(saga.watchForEntitlements))
    yield takeEvery(load.trigger, loginRequired(saga.load))
    yield takeEvery(refresh.trigger, loginRequired(saga.refresh))
    yield takeEvery(federated.trigger, saga.federated)
    yield takeEvery(login.trigger, saga.login)
    yield takeEvery(processOauthCode.trigger, saga.processOauthCode)
    yield takeEvery(logout.trigger, saga.logout)
    yield takeEvery(deactivate.trigger, saga.deactivate)
    yield takeEvery(signup.trigger, saga.signup)
    yield takeEvery(update.trigger, saga.update)
    yield takeEvery(acceptWaiver.trigger, saga.acceptWaiver)
    yield takeEvery(changePassword.trigger, saga.changePassword)
    yield takeEvery(clearSignupErrors.trigger, saga.clearSignupErrors)
    yield takeLatest(forgotPassword.trigger, saga.forgotPassword)
    yield takeLatest(resendConfirmationCode.trigger, saga.resendConfirmationCode)
    yield takeLatest(confirmForgotPassword.trigger, saga.confirmForgotPassword)
    yield takeEvery(changeDefaultStation.trigger, saga.changeDefaultStation)
    yield takeEvery(incrementExperience.trigger, saga.incrementExperience)
    yield takeEvery(getTokenAttributes.trigger, getOnboardingUserAttributes(saga.getTokenAttributes))
    yield takeEvery(submitQuestionnaireAnswers.trigger, saga.submitQuestionnaireAnswers)
    yield takeEvery(getTokenAttributes.trigger, getOnboardingUserAttributes(saga.getTokenAttributes))
  }

  *watchAuthenticated() {
    let userId: string
    const authChangedActions = [TOKEN_UPDATE_ACTION, ...authSuccessActions, Actions.auth.logout.fulfill].map(a =>
      a.toString(),
    )

    while (true) {
      yield take((action: Action<any>) => !userId || authChangedActions.includes(action.type))

      const newUserId = yield select(Selectors.userId)

      if (newUserId !== userId) {
        userId = newUserId
        if (userId) {
          yield put(Actions.auth.authenticated())
        } else {
          yield put(Actions.auth.unauthenticated())
        }
      }

      // if the user is not logged in but they have an id token, then something
      // has gone wrong and we need to clear state
      const idToken = yield select(Selectors.idToken)
      if (!userId && idToken) {
        yield Pvolve.api.tokens.clear()
      }
    }
  }

  *watchTokensReady() {
    while (true) {
      yield race({
        authenticated: take(Actions.auth.authenticated),
        unauthenticated: take(Actions.auth.unauthenticated),
      })

      let tokensReady: boolean
      let tries = 0
      let user = yield select(Selectors.user)

      do {
        if (user) {
          try {
            yield Pvolve.api.tokens.refreshTokens()
          } catch (ex) {
            console.log(ex)
          }
        }

        tokensReady = yield select(Selectors.tokensReady)

        // TODO: currently not doing anything with this action
        // nice to have, but do we need it? code clean up?
        if (tokensReady) {
          yield put(Actions.auth.tokensReady())
        }

        yield delay(5000)

        user = yield select(Selectors.user)
      } while (user && !tokensReady && tries++ < 3)
    }
  }

  *init() {
    yield* this.registerTriggerHandlers()
    yield fork(this.watchAuthenticated)
    yield fork(this.watchTokensReady)
  }
}

export const saga = new AuthSaga()

export default function* combined() {
  console.warn('combined sagas from sdk are deprecated')
  yield saga.init()

  yield takeEvery('persist/REHYDRATE', saga.load)
  yield takeEvery([federated.success, login.success, signup.success], sendTo(load.trigger))
  /* TODO React Native do this in a boot sequence once this is integrated
  yield takeEvery([login.success, signup.success], saga.setBugsnagUser);
  yield takeEvery(logout.success, saga.clearBugsnagUser);
  */
}
