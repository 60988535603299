import { filter, get, first } from 'lodash'
import { Entry, Asset } from 'contentful'
import {
  IWorkout,
  ISeries,
  IBenefit,
  IBodyFocus,
  IEquipment,
  IInstructor,
  IWorkoutCategory,
  IWorkoutType,
  ISeriesCategory,
  ISeriesChapter,
  IPage,
  IWorkoutTypeFields,
  ISeriesCategoryFields,
  IPartnerFields,
} from '../../models/contentful_types'
import { Selector, createSelector } from 'reselect'
import { IMusic } from '../../app/modules/music/types'
import { IVideo } from '../../app/modules/video/types'
import { WORKOUT_DURATIONS } from '../../app/modules/content/actions'

type Duration = keyof typeof WORKOUT_DURATIONS
export interface ContentSearchResult {
  item: IWorkout & { duration: Duration }
}
export interface IContentState {
  data: {
    workout?: object[]
    series?: object[]
    benefit?: object[]
    bodyFocus?: object[]
    equipment?: object[]
    instructor?: object[]
    patner?: object[]
    workoutCategory?: object[]
    workoutHistory?: object[]
    workoutType?: object[]
    seriesCategory?: object[]
    seriesChapter?: object[]
    asset?: object[]
    page?: object[]
    testimonial?: object[]
  }
  searches: {
    default: {
      query: {
        [key: string]: string | string[]
      }
      results: ContentSearchResult[]
    }
  }
}

export interface Benefit {
  name: string
  order: number
}

export interface BodyFocus {
  name: string
  order: number
}

export interface Equipment {
  id: string
  name: string
  icon: string
  order: number
  image: string
}

export interface WorkoutType {
  name: string
  color: string
  backgroundColor: string
}

export interface WorkoutCategoryPreview {
  id: string
  name: string
  slug: string
  icon: string
  color: string
  fontColor: string
  workoutsCount: number
}

export interface ContentV2WorkoutCategory {
  id: string
  name: string
  slug: string
  icon: string
  color: string
  workoutsCount: number
  workoutIds: string[]
}

export interface Trainer {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  slug: string
  bio: string
  image: string
  order: number
}

export interface SeriesOrWorkout {
  id: string
  createdAt: string
  updatedAt: string
  slug: string
  name: string
  description: string
  level: 'advanced' | 'beginner' | 'all levels'
  length: number
  thumbnail: string
  benefit: Benefit[]
  bodyFocus: Benefit[]
  equipment: Equipment[]
  workoutTypes: WorkoutType[]
  trainers: Trainer[]
}

export interface Workout extends SeriesOrWorkout {
  type?: 'rest' | 'walk' | null
  new?: boolean
  date: string
  workoutCategory: WorkoutCategoryPreview
}

export interface ContentV2State {
  series: {
    current: {
      loading: boolean
      series?: SeriesOrWorkout
    }
    index: {
      [key: string]: any
    }
  }
  workouts: {
    current: {
      loading: boolean
      workout?: Workout
    }
    index: {
      [key: string]: Workout
    }
    new: Workout[]
  }

  workoutCategories: {
    images: any[]
    list: ContentV2WorkoutCategory[]
  }
}

export interface IContentSelectors {
  series: IContentSelector<ISeries>
  workout: WorkoutSelectors
  benefit: IContentSelector<IBenefit>
  bodyFocus: IContentSelector<IBodyFocus>
  equipment: IContentSelector<IEquipment>
  instructor: IContentSelector<IInstructor>
  workoutCategory: IContentSelector<IWorkoutCategory>
  workoutType: IContentSelector<IWorkoutType>
  seriesCategory: IContentSelector<ISeriesCategory>
  seriesChapter: IContentSelector<ISeriesChapter>
  asset: IContentSelector<Asset>
  workoutsBySeries: Selector<IState, IContentListIndex<IWorkout>>
  page: IContentSelector<IPage>
}

interface IRoutineState {
  loading: boolean
  fulfilled?: boolean
  error?: any
  loadedAt?: number
}

interface IDataState<T> extends IRoutineState {
  data: T
}

export interface IEnrollmentItem {
  completed_at: string | null
  duration_goal: number
  duration_progress: number
  percentage_completed: number
  series_detail: any
  series_id: string
  series_state: {
    workouts: IDataState<IWorkoutHistoryItem[]>
  }
  started_at: string
  status: EnrollmentStatuses
  user_id: string
}

export enum EnrollmentStatuses {
  quit = 'quit',
  enrolled = 'enrolled',
  completed = 'completed',
}

export enum IWorkoutHistoryStatus {
  not_started = 'not_started',
  in_progress = 'in_progress',
  session_ended = 'session_ended',
}

export interface IWorkoutHistoryItem {
  user_id: string
  session_id: string
  workout_id: string
  video_id: string
  series_id: string
  started_at: any
  completed_at: string
  target_seconds: number
  total_seconds: number
  status: IWorkoutHistoryStatus
  percent_complete: number
  timecode?: number
  is_completed?: boolean
  cycleDay?: number
  phase?: string
}

export enum FavoriteCollectionName {
  workouts = 'workouts',
  instructors = 'instructors',
  series = 'series',
  videos = 'videos',
}

type IFavoriteResults = {
  [key in FavoriteCollectionName]: string[]
}

interface IFavoriteState extends IDataState<IFavoriteResults> {
  version?: number
}

export interface WorkoutStatsSummary {
  workouts_started: number
  workouts_completed: number
  total_minutes: number
  last_started_at: string
}
export interface IWorkoutState {
  history: IDataState<IWorkoutHistoryItem[]>
  stats: {
    data?: {
      user_id: string
      total_summary: WorkoutStatsSummary
      workout_summary: {
        [key: string]: WorkoutStatsSummary
      }
    }
    loading?: boolean
    _persist?: boolean
    failed?: boolean
    loadedAt?: number
  }
  current: IWorkout
}

export interface IWorkoutCategoryState {
  id: string
  order: number
  name: string
  description: string
  slug: string
  workouts: IWorkout[]
  icon?: string
  svgIcon?: string
  tileTextColor?: string
  tileTextBgColor?: string
  workoutBarColors: ICustomDataState
}

export interface IWorkoutCategories {
  workoutCategoriesContent: {
    targetedCategories: IWorkoutCategoryState[]
    defaultCategories: IWorkoutCategoryState[]
  }
}

export interface INextWorkout {
  workout?: IWorkout
  totalWorkouts?: number
  index?: number
  url: string
}

interface IHistoryState {
  enrollments: IDataState<IEnrollmentItem[]>
  currentSeries: {
    data: ISeries
  }
}
interface IPhasesState {
  data: {
    questionnaires: [
      {
        id: string
        responses: [
          {
            question_id: string
            slug: string
            value: string
            cycle_length: string
            period_length: string
            last_period_date: string
          },
        ]
      },
    ]
  }
}

export interface IQuestionnaireState {
  questionnaireContent: IQuestionnaireContentState
}

export interface IQuestionnaireAnswerState {
  question_id: string
  value?: string[] | string
}

export interface IQuestionnaireAnswersState {
  id: string
  responses: IQuestionnaireAnswerState[]
  category: string
  subcategory: string
  series_id: string
  skip: boolean
  updated_at: string
}
export interface IQuestionnaireContentState {
  active?: boolean
  category: string
  cta: string
  error?: string
  id: string
  intro?: ICustomDataState
  name: string
  outro?: ICustomDataState
  questionnaireTemplate?: IQuestionnaireWizardTypeState
  questions:
    | IQuestionnaireInputQuestionTypeState[]
    | IQuestionnaireSelectionQuestionTypeState[]
    | IQuestionnaireRatingQuestionTypeState[]
  subcategory: string
  text?: string
  type: 'SINGLE_PAGE' | 'WIZARD'
  updateUrlParam?: string
  serie?: ISeries
  optional?: boolean
  introTransitionLabel?: string
  outroTransitionLabel?: string
}

export interface ICustomDataState {
  data: Record<string, unknown>
  id: string
  section: string
}
export interface IQuestionnaireWizardTypeState {
  name: string
  columns: number
  color?: string
  image?: string
}

export interface IQuestionnaireSelectionOptionState {
  name: string
  optionText: string
  slug: string
  id: string
  tags: string[]
  default: boolean
}
export interface IQuestionnaireSelectionQuestionTypeState {
  allowMultiple?: boolean
  category?: string
  errorMessage?: string
  id: string
  image?: string
  name: string
  optional?: boolean
  options: IQuestionnaireSelectionOptionState[]
  slug?: string
  subText?: string
  subcategory?: string
  text: string
  type: string
  displayLikePills?: boolean
}
export interface IQuestionnaireInputQuestionTypeState {
  category?: string
  errorMessage?: string
  id: string
  image?: string
  name: string
  optional?: boolean
  slug?: string
  subText?: string
  subcategory?: string
  text: string
  type?: 'number' | 'string' | 'date' | 'boolean' | 'textarea'
  range?: string
  limit?: string
  unit?: string
}

export interface IQuestionnaireRatingQuestionTypeState {
  category?: string
  errorMessage?: string
  id: string
  image?: string
  name: string
  optional?: boolean
  slug?: string
  subText?: string
  subcategory?: string
  text: string
  type: string
  completedWorkText?: string
  congratulatoryText?: string
}

export interface IFeaturedAttributes {
  id: string
  slug: string
  title: string
  description?: string
  color: string
  seriesIds?: string[]
}

export interface ISeriesFeatured {
  id: string
  name: string
  slug: string
  featuredAttributes: {
    [key: string]: IFeaturedAttributes
  }
}

const fulfilled = (state: IState) => state.history.enrollments.fulfilled
const loading = (state: IState) => state.history.enrollments.loading
const loadedAt = (state: IState) => state.history.enrollments.loadedAt
const items = (state: IState) => state.history.enrollments.data

const ready = createSelector(loadedAt, fulfilled, (fulfilled, loadedAt) => !loadedAt && fulfilled)

const enrolled = createSelector(items, items => filter(items, { status: 'enrolled' })[0])

const currentSeries = (state: IState) => state.history.currentSeries.data
const currentWorkout = (state: IState) => state.workouts.current
const workoutTypeCatalog = (state: IState) => state.content.data.workoutType

export const HistorySelectors = {
  enrollments: {
    enrolled,
    items,
    loading,
    fulfilled,
    ready,
  },
  currentSeries,
}

interface WorkoutSelectors extends IContentSelector<IWorkout> {
  byInstructorId: Selector<IState, { [key: string]: IWorkout[] }>
  new: Selector<IState, IWorkout[]>
  next: Selector<IState, INextWorkout>
  workoutDefaultStation: Selector<IState, IWorkoutTypeFields>
}

export const createWorkoutSelectors = (): WorkoutSelectors => {
  const selectors: IContentSelector<IWorkout> = createContentSelectors<IWorkout>('workout')
  const seriesChapter: IContentSelector<ISeriesChapter> = createContentSelectors<ISeriesChapter>('seriesChapter')

  return {
    ...selectors,
    byInstructorId: createSelector(selectors.list, workouts => {
      const instructorWorkouts: { [key: string]: IWorkout[] } = {}
      for (const workout of workouts) {
        if (workout.fields.instructor) {
          for (const instructor of workout.fields.instructor) {
            instructorWorkouts[instructor.sys.id] = instructorWorkouts[instructor.sys.id] || []
            instructorWorkouts[instructor.sys.id].push(workout)
          }
        }
      }
      return instructorWorkouts
    }),
    new: createSelector(selectors.list, workouts => {
      const newWorkouts: { [key: string]: IWorkout } = {}
      for (const workout of workouts) {
        if (workout.fields.new) newWorkouts[workout.sys.id] = workout
      }
      return Object.values(newWorkouts)
    }),
    next: createSelector(selectors.list, seriesChapter.list, currentWorkout, (workouts, seriesList, currentWorkout) => {
      let nextWorkout: IWorkout
      let result: INextWorkout
      let currentWorkoutIndex = 0
      let seriesWorkouts = []

      if (!!currentWorkout) {
        const curSeries = seriesList.find(
          series => series.fields.series.sys.id === currentWorkout.fields.series?.sys.id,
        )
        if (curSeries && curSeries.fields?.autoPlay) {
          let sysWorkout: IWorkout

          seriesWorkouts = curSeries.fields.workouts
          currentWorkoutIndex = seriesWorkouts.findIndex(workout => workout.sys.id === currentWorkout.sys?.id)

          //By default is Last video in the series
          let url = `/series/chapters/${curSeries.fields.slug}`
          if (currentWorkoutIndex < seriesWorkouts.length - 1) {
            sysWorkout = seriesWorkouts[currentWorkoutIndex + 1]
            nextWorkout = workouts.find(workout => workout.sys.id == sysWorkout.sys?.id)
            url = `/workouts/${nextWorkout.fields.slug}/video`
          }

          result = {
            workout: nextWorkout,
            totalWorkouts: seriesWorkouts.length,
            index: currentWorkoutIndex + 1,
            url: url,
          }
        }
      }
      return result
    }),
    workoutDefaultStation: createSelector(
      currentWorkout,
      workoutTypeCatalog,
      (currentWorkout: IWorkout, workoutTypeCatalog: IWorkoutType[]) => {
        let result: IWorkoutTypeFields

        if (!!currentWorkout && !!workoutTypeCatalog) {
          const currentWorkoutTypes: IWorkoutType[] = currentWorkout?.fields?.workoutType || []
          let currentWorkoutTypesData: IWorkoutTypeFields[] = []

          currentWorkoutTypes.forEach(currentType => {
            const currentTypeData: IWorkoutType[] = workoutTypeCatalog.filter((catalogEntry: IWorkoutType) => {
              const fmStation = catalogEntry?.fields?.feedFmStation
              return catalogEntry.sys.id === currentType.sys.id && fmStation !== undefined
            })
            currentWorkoutTypesData.push(currentTypeData[0]?.fields)
          })

          result = first(currentWorkoutTypesData)

          return result
        }

        return result
      },
    ),
  }
}

const workout = createWorkoutSelectors()
// const workoutHistory = createWorkoutSelectors();
const seriesChapter = createContentSelectors<ISeriesChapter>('seriesChapter')
const series = createContentSelectors<ISeries>('series')

const workoutsBySeries = createSelector(
  series.list,
  workout.indexedBy.id,
  seriesChapter.indexedBy.id,
  (seriesList, workoutIndex, seriesChapterIndex) => {
    const workoutsBySeries: IContentListIndex<IWorkout> = {}

    for (const series of seriesList) {
      const {
        sys: { id },
        fields: { chapters },
      } = series

      const workouts: IWorkout[] = []
      for (const {
        sys: { id: chapterId },
      } of chapters || []) {
        const chapter = seriesChapterIndex[chapterId]
        if (!chapter) continue

        const chapterWorkouts = chapter.fields.workouts || []
        for (const {
          sys: { id: workoutId },
        } of chapterWorkouts) {
          const workout = workoutIndex[workoutId]
          if (!workout) continue
          workouts.push(workout)
        }
      }
      workoutsBySeries[id] = workouts
    }

    return workoutsBySeries
  },
)

export const ContentSelectors: IContentSelectors = {
  series,
  workout,
  seriesChapter,
  benefit: createContentSelectors<IBenefit>('benefit'),
  bodyFocus: createContentSelectors<IBodyFocus>('bodyFocus'),
  equipment: createContentSelectors<IEquipment>('equipment'),
  instructor: createContentSelectors<IInstructor>('instructor'),
  workoutCategory: createContentSelectors<IWorkoutCategory>('workoutCategory'),
  workoutType: createContentSelectors<IWorkoutType>('workoutType'),
  seriesCategory: createContentSelectors<ISeriesCategory>('seriesCategory'),
  asset: createContentSelectors<Asset>('asset'),
  page: createContentSelectors<IPage>('page'),
  workoutsBySeries,
}

export enum IFilterTypes {
  BENEFIT = 'benefit', // TODO: Remove this when benefits filter is removed from mobile
  BODY_FOCUS = 'bodyFocus',
  EQUIPMENT = 'equipment',
  INSTRUCTOR = 'instructor',
  LEVEL = 'level',
  DURATION = 'duration',
  WORKOUT_TYPE = 'workoutType',
}

export interface FilterItemsState {
  [key: string]: string | string[]
}

export interface IFilterData {
  id: string
  label: string
}

export interface IFilterConfig {
  label: string
  data: IFilterData[]
  type: IFilterTypes
  hide?: string[]
}

export interface Partner extends IPartnerFields {}

export interface PartnerState {
  current: Partner
  loading: boolean
  error?: unknown
}

export interface Promo {
  active: boolean
  data: {
    image?: string
    headline1: string
    headline2?: string
    closable?: boolean
    body?: string
    copy?: string
    copy2: string
    icon?: string
    cta?: string
    link?: string
    linEntitled?: string
    linkUnentitled?: string
    ctaColor?: string
    ctaTextColor?: string
    textColor?: string
    backgroundColor?: string
    promoType: unknown
    bannerType: 'black' | 'pink'
    moduleType: unknown
  }
}

export interface PromoState {
  data: Promo[]
  loading: boolean
  error?: unknown
}

export interface ISeriesCategoryState {
  seriesCategoryList: ISeriesCategoryFields[]
}
// SDK Selectors
export interface IState {
  config: IConfig
  content: IContentState
  contentV2: ContentV2State
  favorites: IFavoriteState
  history: IHistoryState
  music: IMusic
  workouts: IWorkoutState
  workoutCategories: IWorkoutCategories
  series: {
    enrolled: {
      [key: string]: IEnrollmentItem
    }
    loadingFinished: boolean
    seriesFeatured: {
      [key: string]: ISeriesFeatured
    }
  }
  seriesCategory: ISeriesCategoryState
  partner: PartnerState
  promos: PromoState
  video: IVideo
  phases: IPhasesState
  flags: any
  questionnaire: IQuestionnaireState
  subscriptions: {
    [x: string]: any
  }
  challenge: IChallengeState
}

export interface IContentIndex<T> {
  [key: string]: T
}

export interface IContentListIndex<T> {
  [key: string]: T[]
}

export interface IContentSelector<T> {
  list: Selector<IState, T[]>
  loadedAt: Selector<IState, T[]>
  indexedBy: {
    id: Selector<IState, IContentIndex<T>>
  }
}

export interface IChallengeState {
  data: {
    challenges: any[]
  }
}

function createContentSelectors<T extends Entry<any> | Asset>(contentType: string): IContentSelector<T> {
  const raw = (state: IState) => get(state, `content.data.${contentType}`, [])

  const selectParsed = createSelector(raw, rawList => {
    const indexedBy: {
      id: IContentIndex<T>
    } = {
      id: {},
    }

    const list = new Array<T>()
    for (const rawItem of rawList) {
      const item: T = <T>rawItem
      list.push(item)

      for (const [key, index] of Object.entries(indexedBy)) {
        const value = Reflect.get(item.sys, key) || item.fields[key]
        index[value] = item
      }
    }

    return {
      loadedAt: rawList.loadedAt,
      list,
      indexedBy,
    }
  })

  return {
    loadedAt: createSelector(selectParsed, parsed => parsed.loadedAt),
    list: createSelector(selectParsed, parsed => parsed.list),
    indexedBy: {
      id: createSelector(selectParsed, parsed => parsed?.indexedBy?.id),
    },
  }
}

export interface TimedConfig {
  enabled: boolean
  startTimestamp: [number, number, number, number, number]
  endTimestamp: [number, number, number, number, number]
}

export interface IDefaultPromo extends TimedConfig {}

export interface IOrderConfirmationPromo extends TimedConfig {
  name: string
}

export interface IHeaderCTA extends TimedConfig {
  copy: string
  link: string
}

export interface IIosPromo extends TimedConfig {
  productIds: string[]
  headline1: string
  headline2: string
  body: string
}

export interface IConfig {
  config: {
    environments: {
      [key: string]: {
        uri: string
      }
    }
    menuhash: string
    maxAgeMillis: number
    environment: string
    device: string
    services?: {
      auth: unknown
      account: unknown
      content: unknown
      stats: unknown
      workouts: unknown
      commerce: unknown
      subscription: unknown
    }
    hosts: {
      [key: string]: {
        app: string
        shopify: string
      }
    }
    commerce?: {
      freeShippingSKUs: []
    }
    shopify?: {
      url: string
      cookieDomain: string
    }
    stripe?: {
      publishableKey: string
    }
    cognito?: {
      redirectPath: string
    }
    bugsnag: {
      apiKey: string
    }
    feed?: {
      token: string
      secret: string
      showStationNames?: boolean
    }
    maintenance?: {
      hash: string
      enabled: boolean
      s: string
    }
    header?: {
      cta?: IHeaderCTA[]
    }
    defaultPromo?: IDefaultPromo[]
    orderConfirmationPromo?: IOrderConfirmationPromo[]
    iosPromo?: IIosPromo[]
    featureFlags?: {
      [key: string]: {
        [key: string]: boolean | string
      }
    }
    onboarding?: {
      [key: string]: {
        [key: string]: string
      }
    }
    trialUrl?: {
      [key: string]: {
        [key: string]: boolean
      }
    }
    challenge?: {
      [key: string]: {
        [key: string]: string
      }
    }
  }
}

export const IWorkoutTypes = {
  walk: 'walk',
  rest: 'rest',
  talk: 'talk',
} as const

export type IWorkoutTypes = typeof IWorkoutTypes[keyof typeof IWorkoutTypes]

const config = (state: IState) => state.config?.config
const environments = (state: IState) => state.config?.config?.environments
const environmentName = (state: IState) => state.config?.config?.environment
const services = (state: IState) => state.config?.config?.services
const featureFlags = (state: IState) => state.config?.config?.featureFlags

export const ConfigSelectors = {
  config,
  environments,
  environmentName,
  services,
  featureFlags,
}
