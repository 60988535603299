import BaseService from './BaseService'

/**
 * uses attributes api to store and retrieve the user account
 *
 * future work:
 *  may handle conflict resolution
 *
 * @example
 *    // using CoreService (recommended)
 *    import Core from '@pvolve/sdk';
 *
 *    const { value: account } = await Core.api.account.get();
 *    console.log(account); // should be something like { birthdate: }
 *
 *    await Core.api.account.save({ videos: [] });
 */
class PromoService extends BaseService {
  constructor() {
    super({ configPath: 'services.contentV2' })
  }

  list = async (options = {}) => {
    return await this.requestResource('promosList', {
      tokenType: 'none',
      ...options,
    })
  }
}

export default PromoService
