export const AUTH_SIGNOUT_ACTION = 'SYNC/AUTH/SIGNOUT'

export default class AuthTokenStore {
  constructor(store, options = {}) {
    this.storeKey = options.storeKey || 'auth'
    this.store = store
  }

  logout() {
    const { store } = this
    store.dispatch({
      type: AUTH_SIGNOUT_ACTION,
      payload: null,
    })
  }
}
