import React, { useCallback, useEffect, useRef, useState, memo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { find, first } from 'lodash'
import { Icon } from 'src/components/shared'
import Pvolve from '@pvolve/sdk'
import Selectors from 'src/state/root-selectors.js'
import Actions from '@pvolve/sdk/src/app/actions'
import RootActions from 'src/state/root-actions'
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors'
import FeedStation from './FeedStation'
import FeedInfoTicker from './FeedInfoTicker'
import { FeedEvent, FeedStates, Player, SessionConfig } from '@pvolve/sdk/src/app/modules/music/types'

import * as Styles from 'src/styles/feed-player.module.scss'
import classNames from 'classnames'
import { VideoJsPlayer } from 'video.js'
import { IFeedStation } from '@pvolve/sdk/src/app/modules/music/types'

const INITIAL_COLLAPSE_DELAY = 5000
const PLAYER_COLLAPSE_DELAY = 1500
const HIDE_ALERT_DELAY = 3000

type Timer = NodeJS.Timeout

interface FeedPlayerProps {
    video: VideoJsPlayer
    volumeClicked: boolean
    setMusicPlayerEngaged: (state: boolean) => void
    childRef: any
}

const connector = connect((state: IState) => ({
    music: Selectors.music.music(state),
    workoutDefaultStationName: ContentSelectors.workout.workoutDefaultStation(state)?.feedFmStation,
    lastStationPlayedName: state.account.getAllAttrs?.data?.user?.object?.default_station_id,
    feedFmPlaying: Selectors.account.userAttributes(state)?.object?.feed_fm_playing,
}))

const FeedPlayer = ({
    dispatch,
    music,
    video,
    volumeClicked,
    setMusicPlayerEngaged,
    workoutDefaultStationName,
    lastStationPlayedName,
    feedFmPlaying,
    childRef,
}: FeedPlayerProps & ConnectedProps<typeof connector>) => {
    const [expanded, setExpanded] = useState('expanded')
    const player = useRef<Player>()
    const [available, setAvailable] = useState(true)
    const [loading, setLoading] = useState(true)
    const [info, setInfo] = useState<SessionConfig>()
    const [touched, setTouched] = useState<boolean>(false)
    const [canSkip, setCanSkip] = useState<boolean | undefined>(true)
    const [showStations, setShowStations] = useState(false)
    const [musicAlert, setMusicAlert] = useState(false)
    const [currentStationId, setCurrentStationId] = useState('')
    const alertTimer = useRef<NodeJS.Timer>()
    const expandedTimer = useRef<Timer>()
    const iconSize = 46
    const playIconName = music.state === FeedStates.playing ? 'pv-pause-outline' : 'pv-play-outline'
    const audio_file = info?.current?.play?.audio_file
    const skipButtonClass = `${Styles.button} ${
        canSkip ? Styles.musicButton : [Styles.musicButton, Styles.disabled].join(' ')
    }`

    const isInternationalStation = music.stations.length === 2

    const showAlert = () => {
        if (alertTimer?.current) {
            clearTimeout(alertTimer?.current)
        }

        alertTimer.current = setTimeout(() => setMusicAlert(false), HIDE_ALERT_DELAY)
    }

    const onPlayToggle = useCallback(() => {
        if (player.current) {
            if (music.state === FeedStates.playing) {
                dispatch(Actions.music.pause())
                dispatch(RootActions.account.saveUserAttrs.trigger({ feed_fm_playing: false }))
                player.current.pause()
            } else {
                dispatch(Actions.music.play())
                dispatch(RootActions.account.saveUserAttrs.trigger({ feed_fm_playing: true }))
                player.current.play()
            }
        }
    }, [music, player, dispatch])

    const onSkipPress = useCallback(() => {
        if (player.current) {
            if (canSkip) {
                player.current.skip()
            } else {
                setMusicAlert(true)
                showAlert()
            }
        }
    }, [canSkip, player])

    const onShowStationsPress = useCallback(() => {
        setShowStations(!showStations)
    }, [showStations])

    const togglePlayer = useCallback(() => {
        if (expanded === 'expanded') {
            setExpanded('')
            setMusicPlayerEngaged(false)
        } else {
            setExpanded('expanded')
            setMusicPlayerEngaged(true)
        }
    }, [expanded])

    const hide = useCallback(() => {
        setMusicPlayerEngaged(false)
        setExpanded('')
    }, [video, setMusicPlayerEngaged])

    const onMouseEnter = useCallback(() => {
        expandedTimer.current && clearTimeout(expandedTimer?.current)
        setExpanded('expanded')
        setMusicPlayerEngaged(true)
    }, [video, expandedTimer])

    const onMouseLeave = useCallback(
        (delay: number = PLAYER_COLLAPSE_DELAY) => (e: React.MouseEvent<HTMLElement>) => {
            if (e?.type === FeedStates.playing) {
                video.off('playing', onMouseLeave)
            }
            expandedTimer.current && clearTimeout(expandedTimer?.current)
            expandedTimer.current = setTimeout(hide, delay)
        },
        [hide]
    )

    const onFeedStationPress = (stationId: string) => {
        player?.current?.setStationId(stationId)
        setCurrentStationId(stationId)
    }

    const station = (station: { id: string | number | null | undefined }) => {
        const isActive = station?.id?.toString() === currentStationId?.toString()
        return (
            <FeedStation
                key={station.id}
                station={station}
                isActive={isActive}
                onPress={onFeedStationPress}
                isInternationalStation={isInternationalStation}
            />
        )
    }

    useEffect(() => {
        const handleEvents = async (event: FeedEvent) => {
            setLoading(true)
            switch (event) {
                case FeedEvent.MUSIC_UNAVAILABLE:
                    setAvailable(false)
                    break
                case FeedEvent.SKIP_DENIED:
                    setCanSkip(false)
                    break
                case FeedEvent.STATIONS:
                    if (player.current) {
                        const stations = await player.current.getStations()
                        const defaultStationName = lastStationPlayedName || first(music.stations)?.name

                        dispatch(Actions.music.stations(stations))

                        const activeStation = workoutDefaultStationName
                            ? find(stations, { name: workoutDefaultStationName })
                            : find(stations, { name: defaultStationName })

                        if (!!activeStation) {
                            player.current.setStationId(activeStation.id)
                            setCurrentStationId(activeStation.id)
                        }
                    }
            }

            setLoading(false)

            if (player.current) {
                dispatch(Actions.music.state(player.current.getCurrentState()))
            }
        }

        const initFeed = async () => {
            /**
             * Feed.fm's call to `navigator` is incompatible with Gatsby's SSR
             */
            try {
                const Feed = await require('feed-media-audio-player')
                const config = await Pvolve.api.config.find('feed')

                player.current = new Feed.Player(config?.token || 'demo', config?.secret || 'demo', {
                    debug: process.env.NODE_ENV === 'development',
                })

                childRef(player.current)
            } catch (error) {
                console.warn('failed to initialize feed')
            }

            if (player.current) {
                player.current.tune()
                player.current.on('all', handleEvents)
                player.current.setVolume(music.volume * 100)
                setMusicPlayerEngaged(true)

                if (feedFmPlaying) {
                    try {
                        await player.current.play()
                        dispatch(Actions.music.play())
                    } catch (error) {}
                } else {
                    dispatch(Actions.music.pause())
                    player.current.pause()
                }
            }
        }

        video.on('playing', onMouseLeave(INITIAL_COLLAPSE_DELAY))
        initFeed()

        return () => {
            clearTimeout(expandedTimer?.current)

            if (player.current) {
                player.current.stop()
                player.current.off('all', handleEvents)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expandedTimer, onMouseLeave, video])

    useEffect(() => {
        if (player.current) {
            player.current.setVolume(music.volume * 100)
        }
    }, [music.volume])

    useEffect(() => {
        if (
            !loading &&
            typeof info?.current?.canSkip !== 'undefined' &&
            info?.current?.canSkip !== canSkip &&
            music.state !== FeedStates.idle
        ) {
            if (touched) {
                setCanSkip(info?.current?.canSkip)
            } else {
                setTouched(true)
            }
        }
    }, [loading, info, canSkip, music])

    useEffect(() => {
        if (player.current && lastStationPlayedName) {
            const station: IFeedStation = find(music.stations, { name: lastStationPlayedName })
            player.current.setStationId(station.id.toString())
            setCurrentStationId(station.id)
        }
    }, [lastStationPlayedName, player])

    useEffect(() => {
        if (touched) {
            setMusicAlert(!canSkip)
        }
        alertTimer.current = setTimeout(showAlert, 1000)
    }, [canSkip, touched])

    useEffect(() => {
        if (player.current && music?.state === FeedStates.playing) {
            const { config } = player.current.session
            setInfo(config)
        }
    }, [music, onMouseLeave, player])

    useEffect(() => {
        if (volumeClicked) {
            hide()
        }
    }, [volumeClicked, hide])

    const containerClasses = classNames(Styles.feedPlayerContainer, 'fm-player', {
        showStations,
        expanded,
        musicAlert,
    })

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave(PLAYER_COLLAPSE_DELAY)}
            className={containerClasses}
            id="player-view-div"
        >
            <div className={Styles.stationsContainer}>
                <button className={Styles.close} onClick={onShowStationsPress}>
                    Close
                </button>
                <div className={Styles.stations}>{music.stations.map(station)}</div>
                <p className={`${Styles.credit} small accent bold`}>powered by Feed.fm</p>
            </div>

            <div className={Styles.dynamicContainer}>
                {!canSkip && (
                    <div className={Styles.skipNotice}>
                        You’ve reached your skip limit for this hour. Choose another station for new music.
                    </div>
                )}

                <div className={Styles.controllers}>
                    <div className={Styles.songInfo} onClick={onShowStationsPress}>
                        {audio_file ? (
                            <FeedInfoTicker audio_file={audio_file} />
                        ) : (
                            <div className={Styles.cta}>
                                press play for music <Icon name="pv-arrow-right" color="white" size={13} />
                            </div>
                        )}
                    </div>
                    <Icon
                        name={playIconName}
                        className={`${Styles.button} ${Styles.musicButton}`}
                        size={iconSize}
                        onClick={onPlayToggle}
                    />
                    <Icon name="pv-skip-outline" className={skipButtonClass} size={iconSize} onClick={onSkipPress} />
                </div>
                <Icon
                    name="pv-music"
                    className={`${Styles.button} ${Styles.music}`}
                    size={iconSize}
                    onClick={togglePlayer}
                />
            </div>
        </div>
    )
}

export default connector(memo(FeedPlayer))
