import { createActions } from 'redux-actions'
import { DefaultRoutine, DefaultRoutineActions } from '../../utils'

interface SeriesCategoryActions {
  seriesCategory: {
    getSeriesCategory: DefaultRoutineActions
  }
}

const Actions = <SeriesCategoryActions>(<unknown>createActions({
  SERIES_CATEGORY: {
    GET_SERIES_CATEGORY: DefaultRoutine,
  },
}))

export default Actions
