import { get } from 'lodash'
import { handleActionsImmer } from '../../utils'
import Actions from '../../actions'
import { IVideo } from './types'

export const initialState: IVideo = {
  volume: 0.5,
}

const {
  video: { setVolume },
} = Actions

const Reducer = handleActionsImmer(
  {
    [setVolume]: (draft, data: number) => {
      draft.volume = data
    },
  },
  initialState,
)

export default Reducer
