import BaseService from './BaseService'
import { isEmpty } from 'lodash'
import { sub } from 'date-fns'
import { dateToEpoch, isEmptyValues } from './utils'

interface HistoryQueryParams {
  starting_at?: number
  ending_at?: number
  series_id?: string
  workout_id?: string
}

interface LatestQueryParams {
  starting_at?: number
  series_id?: string
}

class WorkoutService extends BaseService {
  constructor() {
    super({ configPath: 'services.workouts' })
  }

  async enroll(seriesId: string) {
    return await this.requestResource('enroll', {
      method: 'POST',
      data: {
        series_id: seriesId,
      },
    })
  }

  async listEnrolled() {
    return await this.requestResource('listEnrolled', {
      method: 'GET',
    })
  }

  async leave(seriesId: string) {
    return await this.requestResource('leave', {
      method: 'POST',
      data: {
        series_id: seriesId,
      },
    })
  }

  async history(startEpochTs?: number, endEpochTs?: number, seriesIds?: string[], workoutIds?: string[]) {
    const queryParams: HistoryQueryParams = {}

    queryParams.starting_at = startEpochTs
    queryParams.ending_at = endEpochTs

    if (isEmptyValues(queryParams.starting_at)) {
      delete queryParams.starting_at
    }

    if (isEmptyValues(queryParams.ending_at)) {
      delete queryParams.ending_at
    }

    queryParams.series_id = seriesIds?.join()
    if (isEmptyValues(queryParams.series_id)) {
      delete queryParams.series_id
    }

    queryParams.workout_id = workoutIds?.join()
    if (isEmptyValues(queryParams.workout_id)) {
      delete queryParams.workout_id
    }

    return await this.requestResource('history', {
      method: 'GET',
      queryParams,
    })
  }

  async latest(startEpochTs?: number, seriesIds?: string[]) {
    const queryParams: LatestQueryParams = {}

    queryParams.starting_at = startEpochTs
    queryParams.series_id = seriesIds?.join()

    if (isEmptyValues(queryParams.starting_at)) {
      delete queryParams.starting_at
    }

    if (isEmpty(queryParams.series_id)) {
      delete queryParams.series_id
    }

    return await this.requestResource('latest', {
      method: 'GET',
      queryParams,
    })
  }

  async details(sessionId) {
    return await this.requestResource('details', {
      method: 'GET',
      params: { session_id: sessionId },
    })
  }

  async completed_workout(workout_id: string) {
    return await this.requestResource('completed_workout', {
      method: 'POST',
      data: { workout_id },
    })
  }

  async video_events(events) {
    return await this.requestResource('video_events', {
      method: 'POST',
      data: { events },
    })
  }

  async start_session(workout_id: string) {
    return await this.requestResource('start_session', {
      method: 'GET',
      params: { id: workout_id },
    })
  }
}

export default WorkoutService
