import { createActions } from 'redux-actions'
import { BasicActionFunction, DefaultRoutine, DefaultRoutineActions } from '../../utils'

interface MusicActions {
  video: {
    setVolume: BasicActionFunction<number>
  }
}

const Actions = <MusicActions>(<unknown>createActions({
  VIDEO: {
    SET_VOLUME: undefined,
  },
}))

export default Actions
