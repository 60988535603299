import { findIndex, map, set, find, omit } from 'lodash'
import { combineActions } from 'redux-actions'
import { UpdateSubscriptionResponse, Subscription } from '../../../SubscriptionService'
import { Payment, Discount } from '../../../CommerceService'
import Actions from '../../actions'
import { handleActionsImmer } from '../../utils'
import { RoutineState } from '../../utils'

interface ActivationState {
  provider?: string
  data: Record<string, unknown>
}

interface SubscriptionState {
  selectedPlan?: {
    productId: string
    promoCode?: string
  }
  products: any[] // TODO - fix this, on iOS the actual type for this comes from react-native-iap
  purchaseFlow: RoutineState
  loadReceipt: RoutineState
  loadPaymentMethods: RoutineState
  confirmPaymentSetup: RoutineState
  loadProducts: RoutineState
  load: RoutineState
  cancelSubscription: RoutineState
  reactivate: RoutineState
  updatePlan: RoutineState
  submitReceipt: RoutineState
  paymentError: any
  subs: Subscription[]
  latestReceipt: any
  purchaseBypassed: boolean
  paymentMethods?: Payment[]
  discountCodes?: { [code: string]: Discount }
  activation?: ActivationState
}

export const initialState: SubscriptionState = {
  selectedPlan: null,
  products: [],
  purchaseFlow: {},
  loadReceipt: {},
  loadPaymentMethods: {},
  confirmPaymentSetup: {},
  loadProducts: {},
  load: {},
  cancelSubscription: {},
  reactivate: {},
  updatePlan: {},
  submitReceipt: {},
  paymentError: null,
  subs: [],
  latestReceipt: null,
  purchaseBypassed: false,
  paymentMethods: undefined,
  discountCodes: {},
}

const {
  subscriptions: {
    load,
    select,
    loadProducts,
    loadProduct,
    purchaseFlow,
    purchase,
    loadReceipt,
    restorePurchase,
    submitReceipt,
    bypass,
    loadPaymentMethods,
    confirmPaymentSetup,
    cancelSubscription,
    setActivationCode,
    activation,
    activateSubscription,
    reactivate,
    updatePlan,
    getDiscounts,
  },
} = Actions

const Reducer = handleActionsImmer(
  {
    [Actions.auth.logout.success]: draft => ({
      ...initialState,
      discountCodes: draft.discountCodes,
    }),
    [load.success]: (draft, { subscriptions }) => {
      draft.subs = map(subscriptions, sub => {
        if (sub.next_renewal) {
          return {
            ...sub.subscription,
            next_renewal: sub.next_renewal,
            last_invoice: sub?.last_invoice,
          }
        } else {
          return { ...sub.subscription, last_invoice: sub?.last_invoice }
        }
      })
    },
    [submitReceipt.success]: (draft, sub) => {
      draft.subs = [...draft.subs, sub]
    },
    [select]: (draft, { productId, promoCode }) => {
      draft.selectedPlan = { productId }
      if (promoCode) {
        draft.selectedPlan.promoCode = promoCode
      }
    },
    [loadProducts.success]: (draft, products) => {
      draft.products = products
    },
    [setActivationCode.success]: (draft, response) => {
      set(draft, 'setActivationCode.response', response)
    },
    [setActivationCode.trigger]: (draft, payload) => {
      set(draft, 'setActivationCode.code', payload?.code)
    },
    [activation.verify.trigger]: (draft, payload) => {
      set(draft, 'activation.provider', payload.provider)
    },
    [activation.verify.success]: (draft, response) => {
      set(draft, 'activation.data', response)
    },
    [activation.verify.failure]: (draft, response) => {
      // todo
    },
    [activateSubscription.success]: (draft, response) => {
      set(draft, 'activateSubscription.response', response)
    },
    [loadProduct.success]: (draft, product) => {
      const index = findIndex(draft.products, p => p.productId === product.productId)
      if (index > -1) {
        draft.products[index] = product
      } else {
        draft.products = [...draft.products, product]
      }
    },
    [combineActions(purchaseFlow.failure, restorePurchase.failure)]: (draft, payload) => {
      const error = payload.error?.message ?? 'PAYMENT_FAILED'
      draft.paymentError = error
    },
    [combineActions(loadReceipt.success, purchase.success)]: (draft, receipt) => {
      draft.latestReceipt = receipt
    },
    [combineActions(cancelSubscription.success, reactivate.success, updatePlan.success)]: (
      draft,
      updates: UpdateSubscriptionResponse,
    ) => {
      const { data } = updates
      const subscription: Subscription | undefined = find(
        draft.subs,
        sub => sub.subscription_id === data.subscription_id,
      )
      draft.billingAddress = data.billing_address
      if (subscription) {
        subscription.state = data.status
        if (data.next_renewal) {
          subscription.next_renewal = data.next_renewal
        }
      }
    },
    [bypass]: draft => {
      draft.purchaseBypassed = true
    },
    [loadPaymentMethods.success]: (draft, { payment_methods }) => {
      draft.paymentMethods = payment_methods
    },
    [getDiscounts.success]: (draft, discounts: Discount[]) => {
      for (let discount of discounts) {
        const code = discount.code.toLowerCase()
        draft.discountCodes[code] = discount
      }
    },
  },
  initialState,
  {
    purchaseFlow,
    loadReceipt,
    loadPaymentMethods,
    confirmPaymentSetup,
    load,
    loadProducts,
    cancelSubscription,
    setActivationCode,
    activation,
    activateSubscription,
    reactivate,
    updatePlan,
  },
)

export default Reducer
