import ConfigService from './ConfigService'

export default class LocalConfigService extends ConfigService {
  constructor(config) {
    super()
    this._config = config
  }

  async refreshConfig() {
    return this
  }
}
