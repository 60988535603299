import Pvolve from '@pvolve/sdk'
import { isEmpty } from 'lodash'

export async function getVideoSrc(id, type) {
    const video = await Pvolve.api.content.videoSource(type, id, 'video')
    return video
}

export async function getPreviewVideoSrc(id, type) {
    const data = await Pvolve.api.content.publicVideoSource(type, id, 'previewVideo')
    return data
}

export async function getThumbnailSrc(id, type) {
    return await Pvolve.api.content.imageSource(type, id, 'video')
}

export async function getStoryboard(id, type, entitled) {
    const tokenType = entitled ? undefined : 'none'
    const contentType = entitled ? 'video' : 'previewVideo'
    return await Pvolve.api.content.storyboard(type, id, contentType, tokenType)
}

export async function getMuxVideoSource(id, type, fieldName) {
    if (fieldName === 'video') {
        return await getVideoSrc(id, type)
    } else if (fieldName === 'previewVideo') {
        return await getPreviewVideoSrc(id, type)
    }
}

// FIXME: Remove this when P&F is refactored
export async function getQuestionnaire(id) {
    return await Pvolve.api.content.findById('questionnaires', id)
}

export async function getDynamicContent(filter, contains) {
    return await Pvolve.api.content.findByFilterContains('dynamic_content', filter, contains)
}

export async function getFeaturePhaseAndFunction(email) {
    return await Pvolve.api.featureFlag.featureFlagByName(
        ['phaseAndFunctionBeta', 'phaseAndFunctionSeries'],
        'web',
        email
    )
}

export async function getWorkoutsStatsById(id) {
    const response = await Pvolve.api.statsV2.workouts(id)
    return response?.data
}

export async function getProductDetails(sku) {
    const response = await Pvolve.api.subscription.listSubscriptionProducts(sku)
    return response
}

export async function getAssetsByTag(tag) {
    try {
        const assets = await Pvolve.api.contentV2.content.list('assets', {
            tags: tag,
        })

        return assets?.items
    } catch (error) {} //return undefined
}
