import Url from 'url'
import Pvolve from '@pvolve/sdk'
import CognitoService, { ProviderTypes, Tokens } from '@pvolve/sdk/src/CognitoService'

import DefaultAuthBrowser, { AuthBrowser, AuthSessionResult, BrowserResult } from './AuthBrowser'

export class CancellationError extends Error {
  response: BrowserResult

  constructor(response: BrowserResult) {
    super()
    this.constructor = CancellationError
    this.name = 'CancellationError'

    this.response = response
  }
}

type Platforms = 'ios' | 'android'

interface AuthAdapter {
  /**
   * @param provider: facebook, amazon, google
   *
   */
  signInWithOAuth(provider: ProviderTypes, platform: Platforms): Promise<Tokens>
}

export class CognitoAuthAdapter implements AuthAdapter {
  browser: AuthBrowser
  cognito: CognitoService

  constructor(browser: AuthBrowser, cognito: CognitoService) {
    this.browser = browser
    this.cognito = cognito
  }

  async signInWithOAuth(provider: ProviderTypes, platform: Platforms): Promise<Tokens> {
    const { browser } = this

    const linkBase = await Pvolve.api.config.find('app.link')

    const callbackUrl = 'pvolve://login'

    const oauthUrl = await this.cognito.oauthProviderRedirectUrl(callbackUrl, provider)

    const redirectUrl = platform === 'ios' ? encodeURIComponent(callbackUrl) : callbackUrl
    const response: AuthSessionResult = await browser.openAuthSessionAsync(oauthUrl, redirectUrl)

    if (response.type === 'success') {
      let tokens
      // received a response url and parsing out the tokens then constructing our
      // own cognito user
      const url = Url.parse(response.url, true)
      const query = url.query

      const { code } = query

      if (query.code) {
        tokens = await this.cognito.tokensFromCode(<string>code, callbackUrl)
      } else {
        throw {
          message: query.error_description,
          url: response.url,
          ...query,
        }
      }

      if (tokens && tokens.access) {
        return tokens
      } else {
        throw new Error(`access_token not found in: ${response.url}`)
      }
    } else if (response.type === 'cancel' || response.type === 'dismiss') {
      throw new CancellationError(response)
    } else {
      throw new Error(`user responded with: ${response.type}`)
    }
  }
}

export default new CognitoAuthAdapter(new DefaultAuthBrowser(), <CognitoService>Pvolve.api.cognito)
