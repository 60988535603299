import { find, isEmpty, sortBy } from 'lodash'
import { createSelector } from 'reselect'
import {
  format,
  isToday,
  isFuture,
  add,
  startOfWeek,
  endOfWeek,
  differenceInCalendarDays,
  startOfDay,
  endOfDay,
} from 'date-fns'
import { IState, EnrollmentStatuses, ContentSelectors, IWorkoutHistoryItem } from '@pvolve/sdk/src/redux/selectors'
import phases from '@pvolve/sdk/src/app/modules/phaseFunction/selectors'

import WorkoutSelectors from '@pvolve/sdk/src/app/modules/workouts/selectors'
import { INTERNAL_DATE_FORMAT, parseDate } from '@pvolve/sdk/src/app/utils'
import { ISeries, IWorkout, ISeriesFields } from '../../../models/contentful_types'
import Selectors from '../content/selectors'

const userCohort = state => state.account.userCohort || {}

const featureflag = state => state.flags?.phaseAndFunction

// enrollments
const enrollment = (() => {
  const history = (state: IState) => state.series.enrolled
  const current = createSelector(history, pastEnrollments =>
    find(pastEnrollments, e => e.status === EnrollmentStatuses.enrolled || e.status === EnrollmentStatuses.completed),
  )

  const enrollLoadingFinished = (state: IState) => state.series.loadingFinished as boolean

  const calculateWeeksCount = (startDate: Date, endDate: Date) =>
    Math.ceil(
      differenceInCalendarDays(endOfWeek(endDate, { weekStartsOn: 1 }), startOfWeek(startDate, { weekStartsOn: 1 })) /
        7,
    )

  function findAndRemoveWorkoutById(workoutsLeftToDo: IWorkout[], workoutId: string) {
    for (let i = 0; i < workoutsLeftToDo.length; i++) {
      const workout = workoutsLeftToDo[i]
      if (workout.sys.id === workoutId) {
        workoutsLeftToDo.splice(i, 1)
        return workout
      }
    }
  }

  const nonEnrolledSchedule = createSelector(
    WorkoutSelectors.nextWorkoutWhenNotEnrolled,
    WorkoutSelectors.completedHistoryByDate,
    WorkoutSelectors.resumableHistory,
    ContentSelectors.workout.indexedBy.id,
    ContentSelectors.workout.new,
    (nextWorkout, completedByDate, resumableHistory, workoutIndex) => {
      const scheduleItemByDay: { [k: string]: ScheduleItem } = {}

      // startDate is the earliest date with completed activity
      const startDate = Object.keys(completedByDate).reduce((earliestDate, dateString) => {
        const date = parseDate(dateString)
        return date < earliestDate ? date : earliestDate
      }, new Date())

      let dayNumber = 0

      let date = new Date(startDate)

      do {
        date = add(startDate, { days: dayNumber })

        // Find all the workouts that the user completed on `date`
        const dayKey = format(date, INTERNAL_DATE_FORMAT)

        // Building a workout schedule
        const completed = completedByDate[dayKey] || []

        let workout: IWorkout | undefined, workoutHistory: IWorkoutHistoryItem | undefined

        // user did not complete any workouts. so let's assign them one
        if (completed.length === 0 && isToday(date)) {
          // if the user has something resumable then let them resume that
          if (resumableHistory.length > 0) {
            workoutHistory = resumableHistory[0]
            workout = workoutIndex[workoutHistory.workout_id]
          } else {
            // otherwise find the newest workout for them to do
            workout = nextWorkout
          }
        }

        scheduleItemByDay[dayKey] = {
          date: startOfDay(new Date(date)),
          dayNumber,
          completed,
          workout,
          workoutHistory,
        }
        dayNumber++
      } while (!isFuture(date) && !isToday(date))

      return {
        startDate: startOfDay(startDate),
        endDate: endOfDay(date),
        calendarDayCount: dayNumber,
        scheduleItemByDay,
        weeksCount: calculateWeeksCount(startDate, date),
      }
    },
  )

  const enrolledSchedule = createSelector(
    current,
    ContentSelectors.series.indexedBy.id,
    ContentSelectors.workoutsBySeries,
    WorkoutSelectors.completedHistoryByDate,
    WorkoutSelectors.resumableHistory,
    ContentSelectors.workout.indexedBy.id,
    phases.currentPhases,
    featureflag,
    (
      enrollmentItem,
      seriesIndex,
      workoutsBySeries,
      completedByDate,
      resumableHistory,
      workoutsById,
      phaseAttributes,
      featureflag,
    ) => {
      if (!enrollmentItem) {
        return
      }
      const { series_id, started_at } = enrollmentItem
      const series = seriesIndex[series_id]
      if (!series) {
        return
      }
      const workouts = workoutsBySeries[series_id]

      const scheduleItemByDay: { [k: string]: ScheduleItem } = {}

      let dayNumber = 0
      const startDate = startOfDay(started_at ? new Date(started_at) : new Date())

      const workoutsLeftToDo = [...workouts]

      let date = new Date(startDate)

      while (workoutsLeftToDo.length > 0) {
        date = add(startDate, { days: dayNumber })

        // Building a workout schedule
        const completed = []

        // Find all the workouts that the user completed on `date`
        const dayKey = format(date, INTERNAL_DATE_FORMAT)
        for (const historyItem of completedByDate[dayKey] || []) {
          if (historyItem.is_completed) {
            completed.push(historyItem)

            // The user completed a workout in this series so give them credit
            // in the series and don't bring this item up again
            //if (historyItem.series_id === series_id) {
            findAndRemoveWorkoutById(workoutsLeftToDo, historyItem.workout_id)
            //}
          }
        }

        // user did not complete any workouts. so let's assign them one
        let workout: IWorkout | undefined, workoutHistory: IWorkoutHistoryItem | undefined
        if (completed.length === 0 && (isToday(date) || isFuture(date))) {
          // Today's workout screen when no workout has been completed today but
          // we did start something
          if (isToday(date) && resumableHistory.length > 0) {
            // Use the first resumable workout we can find
            workoutHistory = resumableHistory[0]
            // remove from the future workouts if there
            const { workout_id: workoutId } = workoutHistory

            workout = findAndRemoveWorkoutById(workoutsLeftToDo, workoutId) || workoutsById[workoutId]
          } else if (isToday(date) || isFuture(date)) {
            workout = workoutsLeftToDo.shift()
            if (workout) {
              workoutHistory = resumableHistory.filter(item => item.workout_id === workout?.sys?.id)[0]
              // if we have a workout then get the workout history if we have one
            }
          }
        }

        scheduleItemByDay[dayKey] = {
          date: new Date(date),
          dayNumber,
          completed,
          workout,
          workoutHistory,
        }
        dayNumber++
      }

      return {
        series,
        enrollmentItem,
        startDate: startOfDay(startDate),
        endDate: endOfDay(date),
        calendarDayCount: Object.keys(scheduleItemByDay).length,
        scheduleItemByDay,
        weeksCount: calculateWeeksCount(startDate, date),
      }
    },
  )

  const schedule = createSelector(
    enrolledSchedule,
    nonEnrolledSchedule,

    (enrolled, nonEnrolled) => enrolled || nonEnrolled,
  )

  return {
    history,
    current,
    enrollLoadingFinished,
    schedule,
    enrolledSchedule,
    nonEnrolledSchedule,
  }
})()

const sortedSeries = createSelector(ContentSelectors.series.list, (series: ISeries[]) => {
  return sortBy(series, [(seriesItem: ISeries) => seriesItem?.fields?.name])
})

const series = (state: IState) => state.series || {}

const seriesFeatured = createSelector(series, series => series?.seriesFeatured)

const highlightedSeries = createSelector(series, series => series?.highlightedSeries)

const getHighlightedSeries = createSelector(
  highlightedSeries,
  ContentSelectors.series.indexedBy.id,
  (highlightedSeries, seriesIndex) => {
    let highlightedSeriesList = []
    if (!isEmpty(highlightedSeries)) {
      Object.keys(highlightedSeries).forEach((key: string) => {
        const featuredAttributes = highlightedSeries[key]?.featuredAttributes
        if (!isEmpty(featuredAttributes)) {
          Object.values(featuredAttributes).forEach((item: any) => {
            if (!isEmpty(item?.seriesIds)) {
              item?.seriesIds?.forEach((seriesId: string) => {
                const seriesInfo = seriesIndex[seriesId]?.fields
                const data = {
                  id: seriesId,
                  title: item?.title,
                  description: item?.description,
                  color: item?.color ? item?.color : '',
                  isNew: seriesInfo?.new ? seriesInfo?.new : false,
                  thumbnailId: seriesInfo?.thumbnail?.sys?.id,
                  slug: seriesInfo?.slug,
                }
                highlightedSeriesList.push(data)
              })
            }
          })
        }
      })
    }
    return highlightedSeriesList
  },
)

const allSeriesTargeted = createSelector(series, series => series?.allSeriesTargeted)

const getAllSeriesTargeted = createSelector(allSeriesTargeted, allSeriesTargeted => {
  const seriesTargetedList = allSeriesTargeted?.map((series: ISeriesFields) => {
    return {
      sys: { id: series?.id },
      name: series?.name,
    }
  })

  const seriesTargetedListSorted = seriesTargetedList?.sort((a: ISeriesFields, b: ISeriesFields) =>
    a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
  )

  return seriesTargetedListSorted
})

export interface ScheduleItem {
  date: Date
  dayNumber: number
  completed: IWorkoutHistoryItem[]
  workout?: IWorkout
  workoutHistory?: IWorkoutHistoryItem
}

export type EnrollmentSchedule = ReturnType<typeof enrollment.enrolledSchedule>

export default {
  enrollment,
  sortedSeries,
  seriesFeatured,
  getHighlightedSeries,
  getAllSeriesTargeted,
}
