import { createActions } from 'redux-actions'
import { FeedStates, IFeedStation } from '../../../app/modules/music/types'
import { BasicActionFunction, DefaultRoutine, DefaultRoutineActions } from '../../utils'

interface MusicActions {
  music: {
    changeStation: BasicActionFunction<{ station: IFeedStation }>
    pause: BasicActionFunction
    play: BasicActionFunction
    state: BasicActionFunction<FeedStates>
    stations: BasicActionFunction<IFeedStation[]>
    setTrainerVolume: BasicActionFunction<number>
    setVolume: BasicActionFunction<number>
  }
}

const Actions = <MusicActions>(<unknown>createActions({
  MUSIC: {
    CHANGE_STATION: undefined,
    PAUSE: undefined,
    PLAY: undefined,
    STATE: undefined,
    STATIONS: undefined,
    SET_TRAINER_VOLUME: undefined,
    SET_VOLUME: undefined,
  },
}))

export default Actions
