import { createSelector } from 'reselect'

const communications = state => state.settings.communications
const communicationsUpdate = state => state.settings.updateCommunications
const wifiAlert = state => state.settings.wifiAlertVisible

const communicationsData = createSelector(communications, comm => comm.data)

const communicationsLoading = createSelector(communications, comm => comm.loading)

const communicationsUpdateLoading = createSelector(communicationsUpdate, commUp => commUp.loading)

const wifiAlertVisible = createSelector(wifiAlert, wifiAl => wifiAl)

export default {
  communicationsData,
  communicationsLoading,
  communicationsUpdateLoading,
  wifiAlertVisible,
}
