import { BasicActionFunction } from '../../utils'
import { createActions } from 'redux-actions'
import { IdentifyFields, LooseObject, TrackEventBasicActionInterface } from './types'

export interface SegmentActions {
  segment: {
    track: TrackEventBasicActionInterface
    identify: BasicActionFunction<IdentifyFields>
    page: (properties: Record<string, unknown> | undefined) => void
  }
}
const appendWebSource = (props: LooseObject | undefined) => {
  return { ...(props || {}) }
}

const Actions = (trackEventActions: { [k: string]: undefined }) =>
  createActions({
    SEGMENT: {
      TRACK: trackEventActions,
      IDENTIFY: undefined,
      PAGE: (properties: Record<string, unknown> | undefined) => window?.analytics.page(appendWebSource(properties)),
    },
  }) as unknown as SegmentActions

export default Actions
