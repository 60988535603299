import gql from 'graphql-tag'

export default class AppSyncAdapter {
  constructor(core, AppSyncClientClass) {
    this.core = core
    this.AppSyncClientClass = AppSyncClientClass
  }

  async hydratedClient() {
    if (!this.appSyncClient) {
      const { AppSyncClientClass, core } = this
      const config = await core.api.config.find('AppSyncClient')

      this.appSyncClient = new AppSyncClientClass({
        ...config,
        auth: {
          type: 'AMAZON_COGNITO_USER_POOLS',
          jwtToken: async () => {
            const { id } = await core.api.tokens.freshTokens()
            return id
          },
        },
      })
    }
    await this.appSyncClient.hydrated()
    return this.appSyncClient
  }

  async mutate(graphQLQuery, options = {}) {
    const client = await this.hydratedClient()

    let mutation = graphQLQuery
    if (typeof mutation === 'string') {
      mutation = gql(mutation)
    }

    return await client.mutate({ mutation, ...options })
  }

  async query(graphQLQuery, options = {}) {
    const client = await this.hydratedClient()

    let query = graphQLQuery
    if (typeof query === 'string') {
      query = gql(query)
    }

    return await client.query({ query, ...options })
  }
}
