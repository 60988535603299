import { compact, uniq, get, flatten, map, isEmpty } from 'lodash'
import { call, put, takeEvery, race, take, select } from 'redux-saga/effects'

import { loginRequired, routineSaga, sendTo } from '../../utils/sagas'
import Actions from '../../actions'
import Selectors from '../../selectors'
import { CONFIG_UPDATE_ACTION } from '@pvolve/sdk/src/redux'

import Pvolve from '@pvolve/sdk'

//import bugsnag, { updateBugsnagUser } from 'src/bugsnag';
import StripeBridge from './StripeBridge'
import { is } from 'immer/dist/internal'
import { SubscriptionBillingAddress } from '../../../SubscriptionService'

const {
  subscriptions: {
    load,
    purchase,
    loadProducts,
    entitlementsNotAdded,
    loadPaymentMethods,
    setActivationCode,
    activateSubscription,
    cancelSubscription,
    updatePlan,
    reactivate,
    getDiscounts,
    getDefaultPromoDiscount,
    activation,
  },
} = Actions

class SubscriptionSaga {
  private stripe: StripeBridge
  public selectors: typeof Selectors

  constructor() {
    this.selectors = Selectors
  }

  *init() {
    yield takeEvery(loadPaymentMethods.trigger, loginRequired(saga.loadPaymentMethods))
    yield takeEvery(load.trigger, loginRequired(saga.load))
    yield takeEvery(loadProducts.trigger, saga.loadProducts)
    yield takeEvery(setActivationCode.trigger, saga.setActivationCode)
    yield takeEvery(activation.verify.trigger, saga.verifyActivationCode)
    yield takeEvery(activateSubscription.trigger, saga.activateSubscription)
    yield takeEvery(cancelSubscription.trigger, saga.cancelSubscription)
    yield takeEvery(updatePlan.trigger, saga.updatePlan)
    yield takeEvery(reactivate.trigger, saga.reactivate)
    yield takeEvery(
      [Actions.config.loaded, Actions.subscriptions.load.success],
      sendTo(Actions.subscriptions.loadProducts.trigger),
    )
    yield takeEvery(getDiscounts.trigger, saga.getDiscounts)
    yield takeEvery(getDefaultPromoDiscount.trigger, saga.getDefaultPromoDiscount)
  }

  setActivationCode = routineSaga({
    routine: setActivationCode,
    *request({ payload: { code } }) {
      return yield Pvolve.api.subscription.verifyActivationCode(code)
    },
  })

  verifyActivationCode = routineSaga({
    routine: activation.verify,
    *request({ payload: { provider, member_id } }) {
      return yield Pvolve.api.subscription.verifyPartnerActivationCode(provider, member_id)
    },
  })

  activateSubscription = routineSaga({
    routine: activateSubscription,
    *request({ payload: { code } }) {
      return yield Pvolve.api.subscription.activateSubscription(code)
    },
  })

  loadProducts = routineSaga({
    routine: loadProducts,
    *request() {
      const managementProductIds = yield select(saga.selectors.subscriptions.managementProductIds)
      const paywallProductIds = yield select(saga.selectors.subscriptions.productIds)
      let promoProductIds: string[] = []
      const promo = yield select(saga.selectors.subscriptions.defaultPromo)
      if (promo && promo.productIds) {
        promoProductIds = promo.productIds
      }
      const subscription = yield select(saga.selectors.subscriptions.currentSubscription)
      const productId = subscription?.product_id
      const allProductIds = compact(
        uniq(flatten([paywallProductIds, managementProductIds, promoProductIds, productId])),
      )

      return yield Pvolve.api.subscription.listSubscriptionProducts(allProductIds)
    },
  })

  load = routineSaga({
    routine: load,
    *request() {
      return yield Pvolve.api.subscription.get()
    },
    *afterSuccess({ subscriptions }) {
      let codes: string[] = compact(map(subscriptions, sub => sub?.next_renewal?.discount_code))
      if (!isEmpty(codes)) {
        yield put(Actions.subscriptions.getDiscounts.trigger({ codes }))
      }
    },
  })

  loadPaymentMethods = routineSaga({
    routine: loadPaymentMethods,
    *request() {
      const { data } = yield Pvolve.api.commerce.payment()
      const payment_methods = get(data, 'payment_methods', [])
      return { payment_methods }
    },
  })

  cancelSubscription = routineSaga({
    routine: cancelSubscription,
    *request() {
      const { subscription_id } = yield select(saga.selectors.subscriptions.activeSubscription)
      return yield Pvolve.api.subscription.cancel(subscription_id)
    },
  })

  updatePlan = routineSaga({
    routine: updatePlan,
    *request({ payload: { billing_address, new_sku, promo_code } }) {
      const { subscription_id, product_id } = yield select(saga.selectors.subscriptions.currentSubscription)
      const updates: { new_sku?: string; code?: string; billing_address?: SubscriptionBillingAddress } = {}
      if (new_sku && new_sku !== product_id) {
        updates.new_sku = new_sku
      }
      if (promo_code) {
        updates.code = promo_code
      }
      if (billing_address) {
        updates.billing_address = billing_address
      }
      return yield Pvolve.api.subscription.update(subscription_id, {
        ...updates,
        cancel: false,
      })
    },
  })

  reactivate = routineSaga({
    routine: reactivate,
    *request({ payload: { subscription_id } }) {
      return yield Pvolve.api.subscription.update(subscription_id, {
        cancel: false,
      })
    },
  })

  getDiscounts = routineSaga({
    routine: getDiscounts,
    *request({ payload: { codes = [] } }) {
      if (codes && codes.length > 0) {
        return yield Pvolve.api.commerce.getDiscount(codes)
      } else {
        return []
      }
    },
  })

  getDefaultPromoDiscount = routineSaga({
    routine: getDefaultPromoDiscount,
    *request() {
      const defaultPromo = yield select(saga.selectors.subscriptions.defaultPromo)
      if (defaultPromo) {
        yield put(
          Actions.subscriptions.getDiscounts.trigger({
            codes: [defaultPromo.code],
          }),
        )
      }
    },
  })
}

export const saga = new SubscriptionSaga()

function* notifyMissingEntitlements({ payload: { receipt, idToken } }) {
  yield call(notifyPurchaseError, new Error('Entitlements not added'))
}

/*
function* notifyPurchaseError(error, metadata = {}) {
  // if (bugsnag) {
  //   const user = yield select(Selectors.auth.user);

  //   if (error?.response?.config?.url) {
  //     metadata.url = error.response.config.url;
  //   }

  //   updateBugsnagUser(user);
  //   bugsnag.notify(error, report => {
  //     if (metadata) {
  //       report.metadata = {
  //         ...report.metadata,
  //         purchase: metadata
  //       }
  //     }
  //   });
  // }
}

function* notifyMissingProducts({ payload: products = [] }) {
  if (products.length === 0) {
    yield call(notifyPurchaseError, new Error('Failed to load product data'));
  }
}
*/
