import BaseService from './BaseService'
import qs from 'qs'

export interface Tokens {
  access: string
  id: string
  refresh: string
  expiresIn?: number
}

export enum ProviderTypes {
  apple = 'apple',
  facebook = 'facebook',
  google = 'google',
  amazon = 'amazon',
}

const COGNITO_LOGIN_PROVIDERS: {
  [key in ProviderTypes]: string
} = {
  apple: 'SignInWithApple',
  facebook: 'Facebook',
  google: 'Google',
  amazon: 'LoginWithAmazon',
}

export default class CognitoService extends BaseService {
  constructor() {
    super({ configPath: 'cognito' })
  }

  async callIdpCommand(command: string, params: any) {
    const { region, clientId } = await this.config()

    const url = `https://cognito-idp.${region}.amazonaws.com/`

    const headers = {
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': `AWSCognitoIdentityProviderService.${command}`,
    }

    if (false)
      console.log({
        url,
        method: 'POST',
        headers,
        data: {
          ClientId: clientId,
          ...params,
        },
      })
    return await this.request({
      url,
      method: 'POST',
      headers,
      data: {
        ClientId: clientId,
        ...params,
      },
    })
  }

  async login(email: string, password: string): Promise<{ tokens: Tokens }> {
    const { AuthenticationResult: tokens } = await this.callIdpCommand('InitiateAuth', {
      AuthFlow: 'USER_PASSWORD_AUTH',
      AuthParameters: {
        USERNAME: email,
        PASSWORD: password,
      },
    })

    return {
      tokens: {
        access: tokens.AccessToken,
        refresh: tokens.RefreshToken,
        id: tokens.IdToken,
        expiresIn: tokens.ExpiresIn,
      },
    }
  }

  async register(email: string, password: string, optOut: boolean, userAttributes: object, clientMetadata: object) {
    const device = await this.core.api.config.find('device')

    const params: any = {
      Username: email,
      Password: password,
    }

    if (userAttributes) {
      params.UserAttributes = Object.entries(userAttributes).map(([Name, Value]) => ({ Name, Value }))
    }

    if (!params.ClientMetadata) {
      params.ClientMetadata = {}
    }

    const pathname = window?.location?.pathname
    params.ClientMetadata.source = device
    params.ClientMetadata.sourceDetail = window?.location?.pathname?.endsWith('/')
      ? window.location.pathname.slice(0, -1)
      : window?.location?.pathname
    params.ClientMetadata.optOut = String(optOut)

    if (clientMetadata) {
      params.ClientMetadata = clientMetadata
    }

    const { UserConfirmed: confirmed, UserSub: userId } = await this.callIdpCommand('SignUp', params)

    const tokens = await this.login(email, password)

    // If the service is called from here, the tokens are not setted up yet and it's going to cause a 401 error
    // Therefore this call will be moved to signup auth saga
    // await this.core.api.account.saveEmailAttributes(1, { optOut });

    return {
      confirmed,
      userId,
      ...tokens,
    }
  }

  async fetchTokens(data: any): Promise<Tokens> {
    const { domain, clientId: client_id } = await this.config()

    const url = `https://${domain}/oauth2/token`

    const tokens = await this.request({
      url,
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      data: {
        client_id,
        ...data,
      },
    })
    return {
      id: tokens.id_token,
      access: tokens.access_token,
      refresh: tokens.refresh_token || data.refresh_token,
    }
  }

  async refresh(refresh_token: string) {
    const tokens = await this.fetchTokens({
      grant_type: 'refresh_token',
      refresh_token,
    })
    return tokens
  }

  tokensFromCode(code: string, redirect_uri: string) {
    return this.fetchTokens({
      code,
      grant_type: 'authorization_code',
      redirect_uri,
    })
  }

  async oauthProviderRedirectUrl(origin: string, provider: ProviderTypes, options = {}) {
    const {
      domain: domain_name,
      clientId: client_id,
      scope,
      response_type = 'CODE',
      redirectPath,
    } = await this.config()

    const identity_provider = COGNITO_LOGIN_PROVIDERS[provider]
    if (!identity_provider) throw `ProviderNotFound ${provider} not found`

    const redirect_uri = `${origin}${redirectPath}`

    const query = {
      client_id,
      scope,
      identity_provider,
      response_type,
      redirect_uri,
      ...options,
    }

    query.scope = query.scope.join(' ')

    return `https://${domain_name}/oauth2/authorize?${qs.stringify(query)}`
  }

  async forgotPassword(username: string, reset_type: string) {
    return await this.callIdpCommand('ForgotPassword', {
      Username: username,
      ClientMetadata: {
        reset_type,
      },
    })
  }

  async resendConfirmationCode(username: string, reset_type: string) {
    return await this.callIdpCommand('ResendConfirmationCode', {
      Username: username,
      ClientMetadata: {
        reset_type,
      },
    })
  }

  async confirmForgotPassword(username: string, newPassword: string, confirmationCode: string) {
    return await this.callIdpCommand('ConfirmForgotPassword', {
      Username: username,
      ConfirmationCode: confirmationCode,
      Password: newPassword,
    })
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const tokenService = await this._coreInstance._services.tokens.freshTokens()
    const accessToken = tokenService.access

    const params = {
      AccessToken: accessToken,
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword,
    }

    return await this.callIdpCommand('ChangePassword', params)
  }
}
