import BaseService from './BaseService'

class StatsService extends BaseService {
  constructor() {
    super({ configPath: 'services.stats' })
  }

  async workouts() {
    return await this.requestResource('workouts', {
      method: 'GET',
    })
  }
}

export default StatsService
