import { isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import { IState, ContentSelectors, IWorkoutCategoryState } from '../../../redux/selectors'

const workoutCategories = (state: IState) => state.workoutCategories

const workoutCategoriesContent = createSelector(workoutCategories, (workoutCategories: any) => {
  return workoutCategories.workoutCategoriesContent
})

const workoutCategoriesResolve = createSelector(
  workoutCategoriesContent,
  ContentSelectors.workoutCategory.indexedBy.id,
  ContentSelectors.workout.indexedBy.id,
  (workoutCategoriesList, workoutCategoriesIndex, workoutIndex) => {
    let defaultCategoriesResolved: IWorkoutCategoryState[] = []
    let targetedCategoriesResolved: IWorkoutCategoryState[] = []

    if (!isEmpty(workoutCategoriesList)) {
      const { defaultCategories, targetedCategories } = workoutCategoriesList

      if (!isEmpty(defaultCategories)) {
        defaultCategories.forEach((item: any) => {
          const workoutCategoryInfo = workoutCategoriesIndex[item.id]?.fields

          if (workoutCategoryInfo) {
            const workoutInfoList = workoutCategoryInfo?.workouts
              ?.map(workout => workoutIndex[workout.sys.id])
              .filter(v => v)
            const data: IWorkoutCategoryState = {
              id: item.id,
              order: workoutCategoryInfo.order,
              name: workoutCategoryInfo.name,
              description: workoutCategoryInfo.description,
              slug: workoutCategoryInfo.slug,
              workouts: workoutInfoList,
              icon: workoutCategoryInfo.icon,
              svgIcon: workoutCategoryInfo.svgIcon,
              tileTextColor: workoutCategoryInfo.tileTextColor,
              tileTextBgColor: workoutCategoryInfo.tileTextBgColor,
              workoutBarColors: item.workoutBarColors,
            }

            defaultCategoriesResolved.push(data)
          }
        })
      }

      if (!isEmpty(targetedCategories)) {
        targetedCategories.forEach((item: any) => {
          const workoutCategoryInfo = workoutCategoriesIndex[item.id]?.fields

          if (workoutCategoryInfo) {
            const workoutInfoList = workoutCategoryInfo?.workouts
              ?.map(workout => workoutIndex[workout.sys.id])
              .filter(v => v)
            const data: IWorkoutCategoryState = {
              id: item.id,
              order: workoutCategoryInfo.order,
              name: workoutCategoryInfo.name,
              description: workoutCategoryInfo.description,
              slug: workoutCategoryInfo.slug,
              workouts: workoutInfoList,
              icon: workoutCategoryInfo.icon,
              svgIcon: workoutCategoryInfo.svgIcon,
              tileTextColor: workoutCategoryInfo.tileTextColor,
              tileTextBgColor: workoutCategoryInfo.tileTextBgColor,
              workoutBarColors: item.workoutBarColors,
            }

            targetedCategoriesResolved.push(data)
          }
        })
      }
    }

    const workoutCategoriesResolved = {
      defaultCategories: [...defaultCategoriesResolved],
      targetedCategories: [...targetedCategoriesResolved],
    }

    return workoutCategoriesResolved
  },
)

export default {
  workoutCategoriesContent,
  workoutCategoriesResolve,
}
