import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import {
  IQuestionnaireAnswersState,
  IQuestionnaireContentState,
  IQuestionnaireSelectionQuestionTypeState,
  IQuestionnaireAnswerState,
  IQuestionnaireSelectionOptionState,
} from '../../../redux/selectors'
import Pvolve from '@pvolve/sdk'
import { QUESTIONNAIRE_CATEGORY, COHORT_CODES } from './constants'

export const hasQuestionnaire = (questionnaireAnswers = [], category, subactegory) =>
  questionnaireAnswers?.some(
    (answer: IQuestionnaireAnswersState) => answer?.category === category && answer?.subcategory === subactegory,
  )

export const hasAnsweredOnboarding = (questionnairesAnswers = []) =>
  questionnairesAnswers?.some(
    answer => answer?.category === QUESTIONNAIRE_CATEGORY.onboarding && COHORT_CODES.includes(answer.subcategory),
  )

export const getSeriesToJoinOnboarding = async (
  questionnaireContent: IQuestionnaireContentState,
  questionnaireSavedAnswers: IQuestionnaireAnswersState,
  userCohort: string,
) => {
  let tags = []
  let responses = questionnaireSavedAnswers?.responses || []

  const tagsOptionsDictionary = questionnaireContent?.questions?.reduce(
    (prev, current: IQuestionnaireSelectionQuestionTypeState) => {
      return {
        ...prev,
        [current?.id]: current?.options?.reduce((prevOption, currentOption: IQuestionnaireSelectionOptionState) => {
          return {
            ...prevOption,
            [currentOption?.id]: {
              tags: currentOption?.tags,
              isDefault: currentOption?.default,
            },
          }
        }, {}),
      }
    },
    {},
  )

  responses?.forEach((response: IQuestionnaireAnswerState) => {
    const value = response?.value
    if (isEmpty(value)) {
      const questionOptions = tagsOptionsDictionary?.[response?.question_id]
      for (const option in questionOptions) {
        if (questionOptions[option]?.isDefault && !isEmpty(questionOptions[option]?.tags)) {
          tags.push(questionOptions[option]?.tags?.join(','))
        }
      }
    } else {
      const optionValues = isString(response?.value) ? [response?.value] : [...response?.value]
      optionValues?.forEach((value: string) => {
        const optionTags: string[] = tagsOptionsDictionary?.[response?.question_id]?.[value]?.tags
        if (!isEmpty(optionTags)) {
          tags.push(optionTags?.join(','))
        }
      })
    }
  })

  if (isEmpty(tags)) {
    return ''
  }

  tags.push(`cohort${userCohort}`)
  const options = { tags: tags?.join(','), operator: 'all' }
  const seriesFilteredByTag = await Pvolve.api.contentV2.content.list('series', options)
  return isEmpty(seriesFilteredByTag?.items) ? '' : seriesFilteredByTag?.items[0]
}
