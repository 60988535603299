export default class MemoryConfigStore {
  constructor() {
    this._config = {}
  }

  updateConfig(config, metadata) {
    this._config = { metadata, config }
  }

  clear() {
    this.updateConfig()
  }

  get rawConfig() {
    return this._config || {}
  }

  get config() {
    return this.rawConfig.config
  }

  get metadata() {
    return this.rawConfig.metadata || {}
  }
}
