import { includes, template as templ } from 'lodash'
import qs from 'qs'

export default class BaseService {
  constructor({ configPath = null }) {
    this._configPath = configPath
  }

  config = async () => {
    const config = await this.core.api.config.find(this._configPath)
    return config
  }

  clearTokensAndLogout = async () => {
    this._coreInstance._services.auth.authStore.logout()
    await this._coreInstance._services.tokens.clear()
  }

  get core() {
    return this._coreInstance
  }

  set core(coreInstance) {
    this._coreInstance = coreInstance
  }

  rawRequest = async options => {
    // TODO: Consider moving this to somewhere else.
    const authErrors = ['invalid_token', 'invalid_grant', 'unauthorized_client', 'invalid_client']
    const authErrorStatus = [400, 401]

    try {
      const rawResponse = await this.core.requestAdapter.request(options)

      return rawResponse
    } catch (e) {
      if (e?.code === 'ECONNABORTED') {
        throw 'The service is temporily unavailable, please try again at a later time'
        return
      }

      const errorMessage = e?.response?.data?.message
      if (!errorMessage) {
        throw e
      }

      if (includes(authErrorStatus, e?.response?.status) && includes(authErrors, errorMessage?.toLowerCase())) {
        this.clearTokensAndLogout()
      } else if (
        (e?.response?.status === 400 && errorMessage.match(/account already exists/i)) ||
        errorMessage.match(/PreSignUp failed with error Account already exists/i)
      ) {
        throw 'The email associated with this account already exists. Please try one of the login options above'
      } else if (e?.response?.status === 400 && errorMessage === 'Username/client id combination not found.') {
        throw 'The email associated with this account does not exist.'
      } else if (
        (e?.response?.status === 400 && errorMessage.match(/incorrect username or password/i)) ||
        errorMessage?.match(/UserMigration failed with error Invalid username or password/i)
      ) {
        throw 'Incorrect username or password'
      } else if (errorMessage?.match(/timeout/i)) {
        throw 'The service is temporarily unavailable, please try again at a later time'
      } else {
        throw e
      }
    }
  }

  async request(options) {
    const timeout = {
      timeout: 30000,
    }
    const response = await this.rawRequest({ ...options, ...timeout })

    return response?.data
  }

  authRequest = async ({ tokenType = 'id', headers = {}, ...options }) => {
    const { tokens } = this.core.api

    const h = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    }

    if (tokenType === 'optional') {
      tokenType = tokens['id'] ? 'id' : 'none'
    }

    if (tokenType !== 'none') {
      await tokens.freshTokens()
      h.Authorization = `Bearer ${tokens[tokenType]}`
      if (process.env.DEBUG) console.log({ options, token: h.Authorization })
    }
    return await this.request({ headers: h, ...options })
  }

  requestRootResource(options = {}) {
    return this.requestResource(undefined, options)
  }

  resolveUrl = async (methodName, opts = {}) => {
    const { params = {}, query, queryOptions } = opts
    let queryString
    if (query && queryOptions) {
      queryString = qs.stringify(query, queryOptions)
    } else if (query) {
      queryString = qs.stringify(query)
    } else {
      queryString = ''
    }

    const config = await this.config()

    const urlTemplateString = config ? (methodName ? config.resources[methodName] : config.resource) : null

    const template = templ(urlTemplateString, {
      interpolate: URL_INTERPOLATION_RULES,
    })

    return `${template(params)}${queryString ? '?' + queryString : ''}`
  }

  requestResource = async (methodName, opts = {}) => {
    const { params = {}, queryParams, queryOptions, ...options } = opts
    const url = await this.resolveUrl(methodName, { params, query: queryParams, queryOptions })
    return await this.authRequest({ url, ...options })
  }
}

const URL_INTERPOLATION_RULES = /{([\s\S]+?)}/g
