import React from 'react'
import PvolveSelectors from '@pvolve/sdk/src/app/selectors'
import Selectors from 'src/state/root-selectors'

import { connect, ConnectedProps } from 'react-redux'
import { Card } from 'semantic-ui-react'
import { first } from 'lodash'

import { FavoriteButton, Icon, LastCompleted, LinkWrapper } from 'src/components/shared'

import * as Styles from 'src/styles/workout-card.module.scss'
import { FavoriteCollectionName, IState } from '@pvolve/sdk/src/redux/selectors'
import BackgroundImage from 'gatsby-background-image'
import { IWorkout } from '@pvolve/sdk/src/models/contentful_types'
import { ResolvedWorkout } from '@pvolve/sdk/src/app/modules/content/selectors'
import { ContentfulWorkout_Card } from 'src/utils/graphql'
import { useSaveUrl } from '../../utils/url-utils'
import { format, parseISO } from 'date-fns'
import LinkWithPrevPath from 'src/components/LinkWithPrevPath'

interface WorkoutCardProps {
    workout: IWorkout | ResolvedWorkout | ContentfulWorkout_Card
    useBackgroundImage?: boolean
}

const connector = connect((state: IState, { workout, useBackgroundImage }: WorkoutCardProps) => {
    const _workout = (workout as IWorkout)?.sys?.id
        ? PvolveSelectors.content.resolveWorkout(state)((workout as IWorkout)?.sys?.id)
        : (workout as ResolvedWorkout)

    return {
        useBackgroundImage,
        workout: _workout,
        entitled: Selectors.auth.entitled(state),
    }
})

const WorkoutCard = ({ workout, entitled, useBackgroundImage }: ConnectedProps<typeof connector>) => {
    const saveUrl: () => void = useSaveUrl('initialUrl')

    if (!workout) {
        return null
    }

    const {
        name,
        date = '',
        equipment,
        level,
        bodyFocus,
        workoutType,
        slug,
        createdAt = '',
        contentful_id,
        instructor,
        thumbnail,
        video,
        type,
    } = workout

    const isRestOrWalkDay = type === 'rest' || type === 'walk'
    const cardImage = thumbnail?.fluid?.src ? thumbnail.fluid : null
    const backupImage = thumbnail?.fixed?.src
    const formattedDate = date ? format(parseISO(date), 'MM.dd.yy') : ''
    const trainer: { [x: string]: string } | undefined = first(instructor)

    const ThumbContent: React.FC = () => (
        <>
            <Icon className={Styles.playIcon} name="pv-play" size={26} color="black" />
            {!entitled && <h5 className={`bold upper ${Styles.previewTag}`}>Preview</h5>}
            {workout.new && <div className={Styles.newTag}>new</div>}
            <p className={`${Styles.workoutLevel} p1 accent bold color-pv-white`}>{level}</p>
        </>
    )

    return (
        <Card fluid>
            {cardImage || useBackgroundImage ? (
                <BackgroundImage className={Styles.imageContainer} fluid={cardImage || { src: backupImage }}>
                    <LinkWrapper
                        renderItem={LinkWithPrevPath}
                        isRestOrWalkDay={isRestOrWalkDay}
                        props={{ to: `/workouts/${slug}/video`, onClick: saveUrl }}
                    >
                        <ThumbContent />
                    </LinkWrapper>
                </BackgroundImage>
            ) : backupImage ? (
                <LinkWithPrevPath
                    to={`/workouts/${slug}/video`}
                    className={Styles.videoLink}
                    onClick={saveUrl}
                    style={{ backgroundImage: `url(${backupImage}` }}
                >
                    <ThumbContent />
                </LinkWithPrevPath>
            ) : (
                <LinkWithPrevPath to={`/workouts/${slug}/video`} className={Styles.videoLink}>
                    <div className={Styles.placeholder}>
                        <Icon name="pv-logo" size={135} color="gray-50" />
                    </div>
                </LinkWithPrevPath>
            )}

            <Card.Content className={Styles.content}>
                <LinkWrapper isRestOrWalkDay={isRestOrWalkDay} props={{ to: `/workouts/${slug}/video` }}>
                    {name && <Card.Header className={Styles.header}>{name}</Card.Header>}

                    {trainer && (type === 'talk' || bodyFocus) && (
                        <p className={Styles.meta}>
                            with <span>{trainer?.name}</span> &bull; {formattedDate}
                        </p>
                    )}

                    {equipment && !isRestOrWalkDay && (
                        <p className={Styles.meta}>{equipment.map(e => e.name).join(', ')}</p>
                    )}
                </LinkWrapper>

                {!isRestOrWalkDay && (
                    <p className={`${Styles.meta} ${Styles.favIcon}`}>
                        <FavoriteButton collection={FavoriteCollectionName.workouts} id={contentful_id} />
                    </p>
                )}

                <LastCompleted className={Styles.completedAt} workout={workout} label="last completed" />
            </Card.Content>
        </Card>
    )
}

export default connector(WorkoutCard)
