import { all, call, put, race, fork } from 'redux-saga/effects'
import { sequences, getActions, BootSequenceLifecycleActions } from './BootSequence'
import Actions from './actions'
import { toInteger } from 'lodash'

function* runBootSequence(name: string, action: BootSequenceLifecycleActions, haltRunning: boolean) {
  yield put(Actions.boot.begin({ name, action }))

  const actions = getActions(name, action)

  let halted: boolean
  for (let priority of Object.keys(actions).sort()) {
    yield put(Actions.boot.runPriority({ name, action, priority: toInteger(priority) }))

    const { triggers, fulfills, sagas } = actions[priority]

    // trigger all actions
    // fork all sagas
    if (sagas?.length > 0) {
      yield all(sagas.map(fork))
    }

    yield all(triggers.map(trigger => put(trigger({ boot: { name, action } }))))

    const waitForFulfillment = {
      routines: all(fulfills),
      haltRunning,
    }

    const response = yield race(waitForFulfillment)
    halted = response.haltRunning

    if (halted) {
      break
    }
  }

  yield put(Actions.boot.end({ name, action, halted }))

  return halted
}

function* watchBootTrigger(name: string, sequence) {
  const bootSequences: {
    [key in BootSequenceLifecycleActions]?: {
      waitForTrigger: any
      haltRunning: any
    }
  } = {
    start: {
      waitForTrigger: sequence.startWhen,
      haltRunning: sequence.shutdownWhen,
    },
  }

  if (sequence.shutdownWhen) {
    bootSequences.shutdown = {
      waitForTrigger: sequence.shutdownWhen,
      haltRunning: sequence.startWhen,
    }
  }

  while (true) {
    let previouslyHalted

    for (let actionName of Object.keys(bootSequences)) {
      const { waitForTrigger, haltRunning } = bootSequences[actionName]

      if (waitForTrigger) {
        if (!previouslyHalted) {
          yield waitForTrigger
        }

        previouslyHalted = yield call(runBootSequence, name, <BootSequenceLifecycleActions>actionName, haltRunning)
      }
    }
  }
}

export default function* watchForBootTriggers() {
  const bootSequences = sequences()
  for (let name of Object.keys(bootSequences)) {
    yield fork(watchBootTrigger, name, bootSequences[name])
  }
}
