import {
  CTAClickedEventPayload,
  CTAClickedProperties,
  MarketingOptedInEventPayload,
  MarketingOptedInProperties,
  AppButtonClickedEventPayload,
  AppButtonClickedProperties,
  AccountDetailsUpdatedProperties,
  NameCollectionModalProperties,
} from '../types'
import { stringOrUndefined } from '../utils'
import { track_events } from '../events'

const { marketingOptedIn, ctaClicked, appButtonClicked, accountDetailsUpdated, modalViewed, modalSubmitted } =
  track_events

export default {
  events: {
    [marketingOptedIn]: (data: MarketingOptedInEventPayload): MarketingOptedInProperties => {
      return {
        email: data?.email,
        phone: data?.phone,
        is_email_opt_in: data?.is_email_opt_in,
        is_sms_opt_in: data?.is_sms_opt_in,
        source: data?.source,
        source_detail: data?.source_detail,
      }
    },
    [ctaClicked]: (data: CTAClickedEventPayload): CTAClickedProperties => {
      const target = data.event?.target as HTMLAnchorElement
      return {
        cta_name: data?.cta_name,
        cta_text: target?.innerText,
        cta_target_url: stringOrUndefined(target?.href),
        cta_target_domain: stringOrUndefined(target?.hostname),
        cta_target_path: stringOrUndefined(target?.pathname),
      }
    },
    [appButtonClicked]: (data: AppButtonClickedEventPayload): AppButtonClickedProperties => {
      const target = data.event?.target as HTMLAnchorElement
      return {
        app_button_name: data?.app_button_name,
        app_button_text: data?.app_button_text || target?.innerText,
        app_button_target_url: data?.app_button_target_url || stringOrUndefined(target?.href),
        app_button_target_domain: data?.app_button_target_domain || stringOrUndefined(target?.hostname),
        app_button_target_path: data?.app_button_target_path || stringOrUndefined(target?.pathname),
      }
    },
    [accountDetailsUpdated]: (data: AccountDetailsUpdatedProperties): AccountDetailsUpdatedProperties => {
      return {
        firstName: data.firstName,
        lastName: data.lastName,
        birthday: data.birthday,
        gender: data.gender,
        phone: data.phone,
      }
    },
    [modalViewed]: (data: NameCollectionModalProperties): NameCollectionModalProperties => {
      return {
        source: data.source,
        modal_name: data.modal_name,
        modal_detail: data.modal_detail,
      }
    },
    [modalSubmitted]: (data: NameCollectionModalProperties): NameCollectionModalProperties => {
      return {
        source: data.source,
        modal_name: data.modal_name,
        modal_detail: data.modal_detail,
      }
    },
  },
}
