import { compact, get } from 'lodash'
import { createSelector } from 'reselect'
import { ContentSelectors, IState } from '../../../redux/selectors'

const favorites = (state: IState) => state?.favorites?.data

const workoutIds = createSelector(favorites, favorites => favorites?.workouts)

const favoriteWorkouts = createSelector(workoutIds, ContentSelectors.workout.indexedBy.id, (workoutIds = [], index) =>
  compact(workoutIds.map((id: string) => index[id])),
)

const isFavorite = createSelector(favorites, favoritesData => {
  const index: {
    [collectionName: string]: {
      [id: string]: true
    }
  } = {}
  for (const [collectionName, ids] of Object.entries(favoritesData || {})) {
    index[collectionName] = index[collectionName] || {}
    for (const id of ids) {
      index[collectionName][id] = true
    }
  }
  return (collection: string, id: string) => <boolean>get(index, `${collection}.${id}`, false)
})

export default {
  favorites,
  isFavorite,
  workoutIds,
  favoriteWorkouts,
}
