import { createSelector } from 'reselect'
import { compact, find, findLast, get, keyBy, last, map, sortBy, first } from 'lodash'
import moment from 'moment'
import { Product, Subscription } from '@pvolve/sdk/src/SubscriptionService'
import { IState } from '@pvolve/sdk/src/redux/selectors'
import { paymentExpired, inTrialPeriod } from './utils'
import { isMonthly } from '@pvolve/sdk/src/app/modules/subscriptions/utils'
import ConfigSelectors from '../config/selectors'
import ProductGroup from '@pvolve/sdk/src/app/modules/subscriptions/ProductGroup'

const root = (state: IState) => state.subscriptions

const billingAddress = (state: IState) => state.subscriptions?.billingAddress
const selectedProductId = state => state.subscriptions?.selectedPlan?.productId
const selectedPlan = state => state.subscriptions.selectedPlan
const products = (state: IState): Product[] => state.subscriptions.products
const purchaseInProgress = state => state.subscriptions.purchaseFlow.loading
const hasPurchased = state => state.subscriptions.subs.length > 0
const latestReceipt = state => state.subscriptions.latestReceipt
const loadingReceipt = state => state.subscriptions.loadReceipt.loading
const submittingReceipt = state => state.subscriptions.submitReceipt.loading
const paymentError = state => state.subscriptions.paymentError
const bypassed = state => state.subscriptions.purchaseBypassed
const subs = (state: IState): Subscription[] => sortBy(state.subscriptions.subs, 'created_at')
const loadingSubscriptions = state => state.subscriptions.load.loading
const checkedSubscriptions = state => state.subscriptions.load.loadedAt
const loadingProducts = state => state.subscriptions.loadProducts.loading
const cancelling = state => state.subscriptions.cancelSubscription.loading
const purchaseFlow = state => state.subscriptions.purchaseFlow
const paymentMethods = state => sortBy(state.subscriptions.paymentMethods, ['credit_card.created_at'])
const updatingPlan = state => get(state, 'subscriptions.updatePlan.loading')

const paymentMethodsLoading = (state: IState) => state.subscriptions.loadPaymentMethods.loading
const confirmingPaymentSetup = (state: IState) => state.subscriptions.confirmPaymentSetup.loading

const legacyUserMigrationDate = state =>
  get(state, 'config.config.services.subscription.legacyUserMigrationDate', 'work is completed on or before 12/1/2020')

const trialQueryURL = (state: IState) => {
  const baseURL = get(state, 'config.config.services.subscription.trialAttrs.url', '/checkout/?')
  const sku = get(state, 'config.config.services.subscription.trialPages', { default: '12month_trial7' })
  const params = get(state, 'config.config.services.subscription.trialAttrs.params', ['features=true'])

  const query = `sku=${sku['default']}&${params.join('&')}`

  return baseURL.concat(query.toString())
}

const productIds = state => {
  return get(state, 'config.config.services.subscription.productIds.web')
}

const managementProductIds = state => {
  return get(state, 'config.config.services.subscription.managementProductIds.web')
}

const loading = createSelector(
  purchaseInProgress,
  loadingReceipt,
  submittingReceipt,
  loadingSubscriptions,
  (purchasing, receiptloading, submitting, subsLoading) => purchasing || receiptloading || submitting || subsLoading,
)

const isActive = (subscription: Subscription) => ['active', 'trial'].includes(subscription.state)

const activeSubscription = createSelector(subs, subscriptions => {
  return findLast(subscriptions, isActive)
})

const currentSubscription = createSelector(subs, activeSubscription, (subscriptions: Subscription[], active) => {
  return active || last(subscriptions)
})

const lastInvoice = createSelector(subs, activeSubscription, (subscriptions: Subscription[], active) => {
  return active?.last_invoice || last(subscriptions)?.last_invoice
})

const renewalProduct = createSelector(products, latestReceipt, (productList, receipt) => {
  const lastProduct = receipt && find(productList, ({ productId }) => productId === receipt?.productId)
  if (lastProduct) {
    return lastProduct
  } else {
    return productList[0]
  }
})

export const fallbackProductIds = {
  ios: [
    '1_month_14_day_trial',
    '1month_b',
    '3month_b',
    '3_months_access_14_day_trial',
    '6month_b',
    '6_months_access_30_day_trial',
  ],
  default: [],
}

const productsIndex = createSelector(products, productList => {
  return keyBy(productList, 'sku')
})

const currentSubscriptionProduct = createSelector(
  currentSubscription,
  productsIndex,
  (subscription, products) => products[subscription?.product_id],
)

const nextRenewal = createSelector(activeSubscription, sub => sub?.next_renewal)

const nextSubscriptionProduct = createSelector(nextRenewal, productsIndex, (next, index) => next && index[next.sku])

const hasSubscriptionHistory = createSelector(subs, subcriptions => (subcriptions || []).length > 0)

const paymentMethod = createSelector(paymentMethods, methods => find(methods, 'is_default') || last(methods))

const hasActiveSubscription = createSelector(activeSubscription, active => !!active)

const hasJustPurchased = createSelector(purchaseFlow, purchase => {
  if (purchase.loadedAt) {
    if (moment(purchase.loadedAt).add(2, 'hours').isAfter(moment())) {
      return true
    }
  }

  return false
})

const setActivationCode = {
  loading: createSelector(root, root => root?.setActivationCode?.loading),
  product: createSelector(root, root => root?.setActivationCode?.response?.data?.product),
  checkoutFlow: createSelector(root, root => root?.setActivationCode?.response?.data?.validate_only),
  errorMessage: createSelector(root, root => {
    return root?.setActivationCode?.response?.response_code !== 0
      ? root?.setActivationCode?.response?.message
      : undefined
  }),
  code: createSelector(root, root => root?.setActivationCode?.code),
}

const partnerActivation = {
  activation: createSelector(root, root => root?.activation),
}

const activateSubsSelector = createSelector(root, root => root?.activateSubscription)
const activateSubscription = {
  root: activateSubsSelector,
  response: createSelector(activateSubsSelector, root => root?.response),
  subscription: createSelector(activateSubsSelector, root => root?.response?.data?.subscription),
  loading: createSelector(activateSubsSelector, root => root?.loading),
  errorMessage: createSelector(activateSubsSelector, root => {
    return root?.response?.response_code !== 0 ? root?.response?.message : undefined
  }),
}

const paymentStatus = createSelector(paymentMethod, payment => {
  if (!payment) {
    return 'none'
  } else if (paymentExpired(payment)) {
    return 'expired'
  } else {
    return 'valid'
  }
})

// apple_active
// apple_inactive
// active_no_payment
// active_payment_expired
// active
// trial_ending_no_payment
// trial_ending_valid_payment
// trial_ending_expired_payment
// expiring
// canceled
const subscriptionStatus = createSelector(
  currentSubscription,
  currentSubscriptionProduct,
  paymentStatus,
  (sub, product, payment) => {
    if (!sub) {
      return null
    }
    if (sub.provider === 'apple') {
      return isActive(sub) ? 'apple_active' : 'apple_inactive'
    }

    if (isActive(sub)) {
      if (payment === 'none') {
        return 'active_no_payment'
      } else if (payment === 'expired') {
        return 'active_payment_expired'
      } else {
        return 'active'
      }
    } else if (sub.state === 'canceled') {
      if (inTrialPeriod(sub, product)) {
        if (payment === 'none') {
          return 'trial_ending_no_payment'
        } else if (payment === 'expired') {
          return 'trial_ending_expired_payment'
        } else {
          return 'trial_ending_valid_payment'
        }
      } else {
        return 'expiring'
      }
    } else {
      // This actually means sub.state is 'expired' but the statuses
      // for display don't match the status in the subscription data
      return 'canceled'
    }
  },
)

const subscriptionBadgeStatus: (state: IState) => string | void = createSelector(subscriptionStatus, status => {
  if (!status) {
    return
  }

  switch (status) {
    case 'apple_active':
    case 'active':
      return 'active'
    case 'active_no_payment':
    case 'active_payment_expired':
    case 'trial_ending_no_payment':
    case 'trial_ending_valid_payment':
    case 'trial_ending_expired_payment':
    case 'expiring':
      return 'expiring'
    default:
      return 'canceled'
  }
})

const webManagementProducts = createSelector(
  managementProductIds,
  currentSubscriptionProduct,
  productsIndex,
  (productIdList, product, index): Product[] => {
    if (product && !productIdList.includes(product.sku)) {
      productIdList = [product.sku, ...productIdList]
    }

    return compact(map(productIdList, productId => index[productId]))
  },
)

const discountIndex = createSelector(root, root => root?.discountCodes)

const defaultPromo = createSelector(ConfigSelectors.enabledDefaultPromoConfigs, first)

const discountForCode = createSelector(discountIndex, index => code => index[code?.toLowerCase()])

const defaultPromoDiscount = createSelector(defaultPromo, discountIndex, (promo, index) => {
  if (promo) {
    return index[promo.code.toLowerCase()]
  }
})

const nextSubscriptionDiscount = createSelector(nextRenewal, discountForCode, (next, getDiscount) =>
  getDiscount(next?.discount_code),
)

const webPaywallProducts = createSelector(
  productIds,
  hasSubscriptionHistory,
  productsIndex,
  defaultPromoDiscount,
  (newUserProductIds, renewal, index, defaultPromoDiscount) => {
    let productIds = newUserProductIds

    if (renewal) {
      productIds = map(productIds, productId => productId.replace(/_trial\d+/, '_trial0'))
    }

    const products = compact(map(productIds, productId => index[productId]))

    // Note: in most cases, when no promo is running, `defaultPromoDiscount` to be undefined
    // ProductGroup.offers returns the correct fields to render the paywall when `defaultPromoDiscount` is undefined
    const productGroup = new ProductGroup(products, defaultPromoDiscount)

    return map(productGroup?.offers, offer => {
      const { product, discountedBillingPrice, monthlyPrice, discountedMonthlyPrice, hasDiscount, savings } = offer

      const {
        sku,
        name,
        subscription_details: { interval_type, interval, trial_days },
        cost,
      } = product

      let billingDescription

      // monthly products only have a billing description if discounted
      if (hasDiscount) {
        billingDescription = `billed <span class="strike">$${cost?.currency_amount}</span> $${discountedBillingPrice}`
      } else if (!isMonthly(product)) {
        billingDescription = `billed $${cost?.currency_amount}`
      }

      if (billingDescription) {
        if (interval === 12 && interval_type === 'month') {
          billingDescription = `${billingDescription} a year`
        } else if (interval === 1) {
          billingDescription = `${billingDescription} every ${interval_type}`
        } else {
          billingDescription = `${billingDescription} every ${interval} ${interval_type}s`
        }
      }

      let tag
      if (trial_days > 0) {
        tag = `${trial_days}-DAY FREE TRIAL`
      } else if (savings && savings > 0) {
        tag = `SAVE ${savings}%`
      }

      return {
        sku,
        name,
        billingDescription,
        tag,
        cost,
        trial: trial_days > 0,
        monthlyPrice: hasDiscount ? discountedMonthlyPrice : monthlyPrice,
        cta: trial_days > 0 ? 'START FREE TRIAL' : 'GET STARTED',
      }
    })
  },
)

export default {
  billingAddress,
  selectedProductId,
  selectedPlan,
  products,
  purchaseInProgress,
  hasPurchased,
  loading,
  latestReceipt,
  loadingReceipt,
  paymentError,
  bypassed,
  renewalProduct,
  hasSubscriptionHistory,
  hasActiveSubscription,
  paymentMethod,
  paymentMethodsLoading,
  confirmingPaymentSetup,
  subs,
  loadingProducts,
  activeSubscription,
  lastInvoice,
  currentSubscription,
  currentSubscriptionProduct,
  cancelling,
  hasJustPurchased,
  setActivationCode,
  partnerActivation,
  activateSubscription,
  subscriptionStatus,
  subscriptionBadgeStatus,
  paymentStatus,
  checkedSubscriptions,
  webPaywallProducts,
  webManagementProducts,
  updatingPlan,
  managementProductIds,
  productIds,
  legacyUserMigrationDate,
  nextRenewal,
  nextSubscriptionProduct,
  defaultPromo,
  defaultPromoDiscount,
  discountIndex,
  nextSubscriptionDiscount,
  trialQueryURL,
}
