import Actions from './actions'
import { handleActionsImmer } from '../../utils'

export const initialState = {
  data: {},
  version: null,
}

const {
  phases: { list },
} = Actions

export default handleActionsImmer(
  {
    [list.success]: (draft, data) => {
      if (data) {
        draft.version = data.version
        draft.data = data?.object
      }
    },
  },
  initialState,
  {
    list,
  },
)
