import BaseService from './BaseService'

/**
 *
 *
 */
class AuthDispatcherService extends BaseService {
  constructor() {
    super({ configPath: 'auth' })
  }

  async authService() {
    const { type } = (await this.config()) || {}
    return this.core.api[type || 'authRest']
  }

  async register(email, password, optOut, params, clientMetadata) {
    const service = await this.authService()
    return await service.register(email, password, optOut, params, clientMetadata)
  }

  async login(email, password, params) {
    const service = await this.authService()
    return await service.login(email, password, params)
  }

  async logInAs(userId) {
    return await this.requestResource('cx', {
      method: 'POST',
      data: {
        user_id: userId,
      },
    })
  }

  async refresh(refreshToken) {
    const service = await this.authService()
    return await service.refresh(refreshToken)
  }

  set authStore(authStore) {
    this._authStore = authStore
  }

  get authStore() {
    return this._authStore
  }
}

export default AuthDispatcherService
