import { createSelector } from 'reselect'
import { IState } from '../../../redux/selectors'
import ContentSelectors from '../content/selectors'

const seriesCategory = (state: IState) => state.seriesCategory

const seriesCategoryList = createSelector(
  seriesCategory,
  ContentSelectors.resolveSeries,
  (seriesCategory, resolveSeries) => {
    const seriesCategoryList = seriesCategory?.seriesCategoryList?.map(seriesCategory => {
      const { description, name, order, seriesIds, slug, tags } = seriesCategory

      const series = seriesIds?.map(seriesId => {
        return {
          sys: { id: seriesId },
        }
      })

      return {
        slug,
        description,
        name,
        order,
        series,
        tags,
      }
    })

    return seriesCategoryList
  },
)

export default {
  seriesCategoryList,
}
