import BaseService from './BaseService'
import Pvolve from '@pvolve/sdk'

/**
 * uses attributes api to store and retrieve the user account
 *
 * future work:
 *  may handle conflict resolution
 *
 * @example
 *    // using CoreService (recommended)
 *    import Core from '@pvolve/sdk';
 *
 *    const { value: account } = await Core.api.account.get();
 *    console.log(account); // should be something like { birthdate: }
 *
 *    await Core.api.account.save({ videos: [] });
 */

interface QueryParams {
  environment?: string
}
class ContentV2Service extends BaseService {
  constructor() {
    super({ configPath: 'services.contentV2' })
  }

  get workouts() {
    return {
      new: async (options = {}) => {
        let headers = {}
        headers['X-Client-Device'] = await Pvolve.api.config.find('device')

        return await this.requestResource('workoutsNew', {
          tokenType: 'none',
          headers,
          ...options,
        })
      },

      show: async (id: string) => {
        try {
          const queryParams: QueryParams = {}
          const config = await this.config()

          if (config.environment) {
            queryParams.environment = config.environment
          }

          return await this.requestResource('workoutsShow', {
            tokenType: 'none',
            params: { id },
            queryParams,
          })
        } catch (ex) {
          return ex.response?.data
        }
      },
    }
  }

  get content() {
    return {
      list: async (contentType: string, options: {}) => {
        try {
          let headers = {}
          headers['X-Client-Device'] = await Pvolve.api.config.find('device')
          const queryParams: QueryParams = { ...options }
          const config = await this.config()

          if (config.environment) {
            queryParams.environment = config.environment
          }

          return await this.requestResource('list', {
            tokenType: 'none',
            headers,
            params: { contentType },
            queryParams,
          })
        } catch (ex) {
          return ex.response?.data
        }
      },

      show: async (id: string, contentType: string) => {
        try {
          const queryParams: QueryParams = {}
          const config = await this.config()

          if (config.environment) {
            queryParams.environment = config.environment
          }

          return await this.requestResource('show', {
            tokenType: 'none',
            params: { contentType, id },
            queryParams,
          })
        } catch (ex) {
          return ex.response?.data
        }
      },
    }
  }

  get workoutCategories() {
    return {
      list: async (options = {}) => {
        let headers = {}
        headers['X-Client-Device'] = await Pvolve.api.config.find('device')

        return await this.requestResource('workoutCategoriesList', {
          tokenType: 'none',
          headers,
          ...options,
        })
      },
    }
  }

  async seriesFeaturedList(options = {}) {
    try {
      let headers = {}
      headers['X-Client-Device'] = await Pvolve.api.config.find('device')

      return await this.requestResource('seriesFeatured', {
        tokenType: 'none',
        params: {
          filter: '',
        },
        headers,
        ...options,
      })
    } catch (ex) {
      console.log({ ex })
      return ex.response.data
    }
  }

  async seriesFeaturedFilter(filteredBy: string, options = {}) {
    try {
      let headers = {}
      headers['X-Client-Device'] = await Pvolve.api.config.find('device')

      return await this.requestResource('seriesFeatured', {
        tokenType: 'none',
        params: {
          filter: `?filter=${filteredBy}`,
        },
        headers,
        ...options,
      })
    } catch (ex) {
      console.log({ ex })
      return ex.response.data
    }
  }
}

export default ContentV2Service
