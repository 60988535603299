import BaseService from './BaseService'
import Pvolve from '@pvolve/sdk'

/**
 * uses attributes api to store and retrieve the user account
 *
 * future work:
 *  may handle conflict resolution
 *
 * @example
 *    // using CoreService (recommended)
 *    import Core from '@pvolve/sdk';
 *
 *    const { value: account } = await Core.api.account.get();
 *    console.log(account); // should be something like { birthdate: }
 *
 *    await Core.api.account.save({ videos: [] });
 */
class ContentService extends BaseService {
  constructor() {
    super({ configPath: 'services.content' })
  }

  async find(contentType, options = {}) {
    try {
      let headers = {}
      headers['X-Client-Device'] = await Pvolve.api.config.find('device')

      const queryParams = {}
      const config = await this.config()
      if (config.environment) {
        queryParams.environment = config.environment
      }

      return await this.requestResource('find', {
        tokenType: 'optional',
        params: { contentType },
        queryParams,
        headers,
        ...options,
      })
    } catch (ex) {
      console.log({ ex })
      return ex.response.data
    }
  }

  async videoSource(contentType, id, fieldName) {
    try {
      const queryParams = {}
      const config = await this.config()
      if (config.environment) {
        queryParams.environment = config.environment
      }
      return await this.requestResource('video', {
        params: { contentType, id, fieldName },
        queryParams,
      })
    } catch (ex) {
      console.log({ ex })
      return ex.response.data
    }
  }

  async storyboard(contentType, id, fieldName, tokenType) {
    try {
      const queryParams = {}
      const config = await this.config()
      if (config.environment) {
        queryParams.environment = config.environment
      }
      return await this.requestResource('storyboard', {
        tokenType,
        params: { contentType, id, fieldName },
        queryParams,
      })
    } catch (ex) {
      console.log({ ex })
      return ex.response?.data
    }
  }

  async publicVideoSource(contentType, id, fieldName) {
    try {
      const queryParams = {}
      const config = await this.config()
      if (config.environment) {
        queryParams.environment = config.environment
      }
      return await this.requestResource('publicVideo', {
        tokenType: 'none',
        params: { contentType, id, fieldName },
        queryParams,
      })
    } catch (ex) {
      console.log({ ex })
      return ex.response.data
    }
  }

  async imageSource(contentType, id, fieldName, imageQuery = {}) {
    try {
      const query = { ...imageQuery }
      const config = await this.config()
      if (config.environment) {
        query.environment = config.environment
      }

      const uri = await this.resolveUrl('image', {
        params: { contentType, id, fieldName },
        query,
      })
      return { uri, headers: { Authorization: this.core.api.tokens.id } }
    } catch (ex) {
      console.log({ ex })
      return ex.response.data
    }
  }

  async findById(contentType, id, options = {}) {
    try {
      let headers = {}
      headers['X-Client-Device'] = await Pvolve.api.config.find('device')

      const queryParams = {}
      const config = await this.config()
      if (config.environment) {
        queryParams.environment = config.environment
      }

      return await this.requestResource('findById', {
        tokenType: 'optional',
        params: { contentType, id },
        queryParams,
        headers,
        ...options,
      })
    } catch (ex) {
      console.log({ ex })
      return ex.response.data
    }
  }

  async findByFilterContains(contentType, filter, contains, options = {}) {
    try {
      const queryParams = {}
      const config = await this.config()
      if (config.environment) {
        queryParams.environment = config.environment
      }

      return await this.requestResource('findByFilterContains', {
        tokenType: 'none',
        params: {
          contentType,
          filter: !!filter ? `filter=${filter}` : '',
          contains: !!contains ? (!!filter ? `&contains=${contains}` : `contains=${contains}`) : '',
        },
        queryParams,
        ...options,
      })
    } catch (ex) {
      console.log({ ex })
      return ex.response.data
    }
  }
}

export default ContentService
